@import '../../styles/variables';

.marketsearchtoolsearchcontainer-box-container {
    .filtergroup-filter-footer {
        justify-content: space-between;
    }

    .filtergroup-form-field-container {
        border-bottom: 0;
    }
}

@media (max-width: $screen-sm) {
    .marketsearchtoolsearchcontainer-box-container {
        .filtergroup-filter-footer .clear-filter-button {
            width: auto;
        }
    }
}
