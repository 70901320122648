@import '../../styles/variables';

.scroll-to-top {
    display: none;

    &.show {
        display: flex;
        position: fixed;
        bottom: 24px;
        right: 24px;
        z-index: 101;

        margin: 0;
        cursor: pointer;

        > button {
            > span {
                padding-left: $grid-gutter-width-onequarter;
                position: relative;
                top: -3px;
            }
        }
    }
}
