@import '../../../styles/variables';

.guide-pathway-wrapper {
    a.guide-pathway-anchor {
        text-decoration: none !important;
        display: block;
        height: 100%;

        .guide-pathway-card {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-content: space-between;
            height: 100%;
            padding: $grid-gutter-width-threequarters;
            background-color: $bga-white;
            color: $bga-black;

            .guide-pathway-card-content {
                .label-holder {
                    display: flex;
                    padding-bottom: $grid-gutter-width-threequarters;
                }
                h3.guide-pathway-card-title {
                    font-size: $h4-font-size;
                    line-height: 28.5px;
                }
            }

            .guide-pathway-footer {
                margin-top: auto;
            }

            &.show-side-bar {
                border-left-style: solid;
                border-left-width: 8px;

                &.blue {
                    border-left-color: $bga-blue-highlight-shadow;
                }

                &.yellow {
                    border-left-color: $bga-yellow-highlight-shadow;
                }

                &.teal {
                    border-left-color: $bga-teal-highlight-shadow;
                }

                &.orange {
                    border-left-color: $bga-orange-highlight-shadow;
                }

                &.green {
                    border-left-color: $bga-green-highlight-shadow;
                }

                &.purple {
                    border-left-color: $bga-purple-highlight-shadow;
                }
            }

            &.show-hover-background:hover,
            &.show-hover-background:active,
            &.show-hover-background:focus {
                cursor: pointer;
                box-shadow: none !important;

                .guide-pathway-card-content {
                    .label-holder {
                        .tag {
                            background-color: $bga-white;
                        }
                    }
                }

                &.blue {
                    background-color: $bga-blue-highlight-light;
                }

                &.yellow {
                    background-color: $bga-yellow-highlight-light;
                }

                &.teal {
                    background-color: $bga-teal-highlight-light;
                }

                &.orange {
                    background-color: $bga-orange-highlight-light;
                }

                &.green {
                    background-color: $bga-green-highlight-light;
                }

                &.purple {
                    background-color: $bga-purple-highlight-light;
                }
            }
        }

        &.show-border {
            border: 1px solid $bga-blue-border;

            &:hover,
            &:active,
            &:focus {
                border: 2px solid $bga-blue-focus;
            }
        }

        &:hover,
        &:active,
        &:focus {
            .guide-pathway-card {
                .guide-pathway-card-content {
                    .guide-pathway-card-title {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    .guide-pathway-wrapper {
        a.guide-pathway-anchor {
            .guide-pathway-card {
                padding: $grid-gutter-width-half;
            }
        }
    }
}
