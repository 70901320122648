@import '../../../styles/variables';

@mixin nested-filter-checkbox-container($checkboxColor) {
    .focus-outline {
        outline: none;
        border-radius: 4px;
        border: 1px solid $bga-blue-background-dark;
        box-shadow: 0 0 0 1px $bga-blue-background-dark;
    }

    width: 100%;
    padding-top: $grid-gutter-width-onequarter * 1.5;

    .filter-checkbox-item {
        .filter-checkbox-field-wrapper {
            display: flex;
            flex-wrap: nowrap;

            .filter-checkbox-field {
                line-height: 28px;
                display: inline-block;
                margin: 0;
                padding: 0;
                background: $bga-white;

                input {
                    &:focus + label {
                        @extend .focus-outline;
                    }

                    &:not(:checked) {
                        position: absolute;
                        left: -9999px;
                    }

                    &:checked {
                        &:disabled + label {
                            &:before {
                                outline: none;
                                border: solid 1px $bga-grey;
                                background-color: $bga-white-smoke;
                                cursor: default;
                            }

                            color: $bga-oslo-grey;
                            cursor: default;
                        }

                        position: absolute;
                        left: -9999px;
                    }

                    &:disabled {
                        &:not(:checked) + label {
                            &:before {
                                outline: none;
                                border: solid 1px $bga-grey;
                                background-color: $bga-white-smoke;
                                cursor: default;
                            }

                            color: $bga-oslo-grey;
                            cursor: default;
                        }
                    }

                    &:checked + label {
                        position: relative;
                        cursor: pointer;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        color: $checkboxColor;
                        font-weight: $font-weight-semibold;

                        &:before {
                            content: '';
                            min-width: 28px;
                            height: 28px;
                            margin-right: 14px;
                            background: $bga-white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            box-sizing: border-box;
                            border: 3px solid $checkboxColor;
                        }

                        svg {
                            display: block;
                            height: 16px;
                            width: 16px;
                            left: 14px;
                        }
                    }

                    &:not(:checked) + label {
                        position: relative;
                        cursor: pointer;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        color: $bga-black;
                        font-weight: normal;

                        &:before {
                            border: solid 1px $bga-black;
                            content: '';
                            min-width: 28px;
                            height: 28px;
                            margin-right: 14px;
                            background: $bga-white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            box-sizing: border-box;
                        }
                    }
                }

                .filter-checkbox-label {
                    margin: 0;
                    border: none;
                    box-sizing: border-box;
                    padding: $grid-gutter-width-onequarter;
                }

                svg {
                    display: none;
                    position: absolute;
                    left: 15px;
                }

                .filter-checkbox-count {
                    display: inline;
                    color: $bga-black;
                }
            }

            .filter-checkbox-state {
                display: flex;
                width: 20%;
                right: 16px;
                justify-content: right;
                margin-left: auto;

                .nested-filter-checkbox-chevron {
                    align-content: center;

                    &:focus {
                        @extend .focus-outline;
                    }

                    .svg-open {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}

@mixin filter-inner-checkbox-container($checkboxColor) {
    width: 100%;
    background: $bga-grey;
    padding: $grid-gutter-width-half 0 $grid-gutter-width-half ($grid-gutter-width * 0.625);
    display: block;

    .filter-checkbox-item-inner {
        display: flex;
        margin: 0 0 $grid-gutter-width-onequarter;

        &:last-of-type {
            margin-bottom: 0;
        }

        .filter-checkbox-field-inner {
            line-height: 28px;
            display: block;
            padding: 0;
            background: $bga-white;

            input {
                &:focus + label {
                    @extend .focus-outline;
                }

                &:not(:checked) {
                    position: absolute;
                    left: -9999px;
                }

                &:checked {
                    &:disabled + label {
                        &:before {
                            outline: none;
                            border: solid 1px $bga-grey;
                            background-color: $bga-white-smoke;
                            cursor: default;
                        }

                        color: $bga-oslo-grey;
                        cursor: default;
                    }

                    position: absolute;
                    left: -9999px;
                }

                &:disabled {
                    &:not(:checked) + label {
                        &:before {
                            outline: none;
                            border: solid 1px $bga-grey;
                            background-color: $bga-white-smoke;
                            cursor: default;
                        }

                        color: $bga-oslo-grey;
                        cursor: default;
                    }
                }

                &:checked + label {
                    position: relative;
                    cursor: pointer;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: $checkboxColor;
                    font-weight: $font-weight-semibold;

                    &:before {
                        content: '';
                        min-width: 28px;
                        height: 28px;
                        margin-right: 14px;
                        background: $bga-white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-sizing: border-box;
                        border: 3px solid $checkboxColor;
                    }

                    svg {
                        display: block;
                    }
                }

                &:not(:checked) + label {
                    position: relative;
                    cursor: pointer;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: $bga-black;

                    &:before {
                        border: solid 1px $bga-black;
                        content: '';
                        min-width: 28px;
                        height: 28px;
                        margin-right: 14px;
                        background: $bga-white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-sizing: border-box;
                    }
                }
            }

            .filter-checkbox-label {
                width: 100%;
                padding: 0 $grid-gutter-width-onequarter;
                box-sizing: border-box;
                background: $bga-grey;
            }

            svg {
                display: none;
                position: absolute;
                left: 15px;
            }
        }
    }
}

.hide-element {
    display: none;
}

.nested-filter-checkbox-container {
    @include nested-filter-checkbox-container($bga-blue-links);
}
.filter-inner-checkbox-container {
    @include filter-inner-checkbox-container($bga-blue-links);
}
