@import '../../styles/variables';

.news-or-events-page-container {
    display: flex;

    .sidebar-content {
        position: sticky;
        top: 0;
    }

    @media (max-width: $screen-md-max) {
        flex-direction: column-reverse;

        .sidebar-content {
            position: relative;
        }
    }
}
