﻿@import 'variables';

@mixin headings() {
    h1 {
        font-weight: $font-weight-bold;
        letter-spacing: 0;
        line-height: 66px;
    }

    h2 {
        font-weight: $font-weight-semibold;
        letter-spacing: 0;
        line-height: 51px;
    }

    h3 {
        letter-spacing: 0;
        line-height: 33px;
    }

    h4 {
        letter-spacing: 0;
        line-height: 28.5px;
    }

    h5 {
        letter-spacing: 0;
        line-height: 28px;
    }

    @media (max-width: $screen-xs-max) {
        h1 {
            font-size: 32px;
            line-height: 48px;
        }

        h2 {
            font-size: 28px;
            line-height: 42px;
        }
    }

    .body-copy-wrapper:has(.body-copy):has(h2) {
        div.body-copy > h2 {
            padding: 0;
        }
        ~ .body-copy-wrapper:has(.body-copy):has(h2) div.body-copy > h2 {
            padding-top: $grid-gutter-width-threequarters;
        }
    }
}
