@import '../../styles/variables';

.market-explorer-box-form-field-container {
    box-sizing: border-box;
    margin-bottom: 30px;
}

.market-explorer-box-container {
    display: block;
    width: 100%;
}
