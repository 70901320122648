@import '../../styles/variables';

.progressbar-container {
    background-color: lightgrey;
}

.progress-bar {
    background-color: $bga-blue-light;
    color: #cccccc;
    padding-right: 0.35rem;
    white-space: nowrap;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 2rem;
}

.progress-text {
    align-self: center;
    font-size: 0.85rem;
}
