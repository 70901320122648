@import '../../styles/variables';

.tickbox-list {
    ol {
        li {
            list-style: none;
            padding-left: $grid-gutter-width-half;
            position: relative;

            &::before {
                content: url('../../assets/listTick.svg');
                display: inline-block;
                position: absolute;
                left: -1rem;
                top: 2px;
            }
        }
    }
}
