@import '../../styles/variables';

@media (max-width: $screen-sm) {
    table.table {
        td:not(:first-of-type)::before {
            padding-right: 5% !important;
        }
    }

    table[summary] {
        table-layout: fixed;
        width: 100%;

        th.applied_tabletitle {
            font-size: 0.8em;
        }

        td.applied_tablecontent {
            display: table-cell;
            background: $bga-white;
            overflow-wrap: break-word;
            padding-left: $grid-gutter-width-onequarter !important;
            padding-right: $grid-gutter-width-onequarter !important;
            border-top: 0 !important;
        }

        td:first-of-type.applied_tablecontent {
            font-size: 0.8em;
        }
    }
}
