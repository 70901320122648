@import '../../styles/variables';

.breadcrumb-holder {
    background-color: $bga-blue-background-dark;
    min-height: 3rem;
}

.breadcrumb-home-wrapper {
    text-align: left;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;

    @at-root {
        ul#{&} {
            padding-left: 0;

            > li {
                display: inline-block;
                line-height: 1.5rem;
            }
        }
    }
}

.breadcrumb-link {
    display: block;
    color: $bga-white;
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 0;
    text-decoration: underline;
    border: solid 2px transparent;
    border-radius: $bga-focus-radius;
    padding-right: 4px;

    &:hover {
        color: $bga-white;
        text-decoration: none;
    }
    &:focus-visible {
        border-color: $bga-white;
        outline: none;
    }
}

.left-arrow {
    padding-bottom: 3px;
}

.breadcrumb-location {
    color: $bga-white;
    display: inline;
    font-size: 0.875rem;
    font-weight: 400;
}
