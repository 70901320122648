@import '../../../styles/variables';

.contacts {
    section {
        padding-bottom: 8px;
    }

    a[href*="//"]:after
    {
        content: '';
    }

    a {
        color: $bga-blue-light;
        text-decoration: none;
        box-shadow: none;
        border-bottom: solid 2px $bga-blue-focus;
        font-weight: bold;

        &:visited {
            color: $bga-hibiscus;
            box-shadow: none;
            border-bottom: solid 2px #dfb1d0;
        }

        &:focus {
            border: 2px solid $bga-mariner;
            border-radius: 3px;
            background-color: $bga-blue-chalk;
            box-shadow: none;
            outline: none;
        }

        &:hover {
            color: $bga-blue-light;
            box-shadow: inset 0 -20px 0 $bga-pattens-blue;
            border-bottom: none;
            text-decoration: none;
        }

        &:active {
            color: $bga-black;
            box-shadow: none;
            border-bottom: solid 2px $bga-blue-focus;
        }
    }

    &:active {
        color: $bga-black;
        box-shadow: none;
        border-bottom: solid 2px $bga-blue-focus;
    }
}
