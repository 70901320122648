@import '../../styles/variables';
@import '../../styles/reactSelect';

.commoditiessearch-ahecc-container {
    + .tooltip-text {
        margin-top: $grid-gutter-width-onequarter;
    }

    .notification-image {
        padding-right: $grid-gutter-width-onequarter;

        img {
            height: 32px;
            width: 32px;
        }
    }

    .ahecc-container-message {
        padding-top: $grid-gutter-width-threequarters;
    }

    .ahecc-container-message-padding {
        padding-left: 40px;
    }

    a {
        color: $bga-white;
        text-decoration: none;
        box-shadow: none;
        border-bottom: solid 2px $bga-white;
    }

    a:focus {
        color: $bga-blue-light;
        box-shadow: inset 0 -20px 0 $bga-blue-highlight-dark;
        border-bottom: none;
    }

    a:hover {
        color: $bga-blue-light;
        box-shadow: inset 0 -20px 0 $bga-blue-highlight-dark;
        border-bottom: none;
    }

    a:active {
        color: $bga-black;
        box-shadow: inset 0 -20px 0 $bga-blue-highlight-dark;
        border-bottom: none;
    }

    .match-highlight {
        display: inline-block;
        padding: 0.25em 0;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out;
        transition: box-shadow 0.15s ease-in-out;
        color: #212529;
        background-color: $bga-blue-chalk;
    }
}

.commoditiessearch-pnp-Container,
.commoditiessearch-lawsnregs-Container,
.commoditiessearch-marketsearch-Container {
    padding-top: 0;
}

.commodity-search__indicators {
    @include _react-select-indicators;
}

.commodity-search__single-value {
    @include _react-select-single-value;
}

.commodity-search__menu {
    @include _react-select-menu;
}

.commodity-search__control {
    @include _react-select-control;
}
