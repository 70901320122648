@import '../../../styles/variables';

.radio-button-group {
    &.stacked {
        > .radio-button {
            display: block;
            width: 350px;
            padding: 10px 40px 10px 49px;

            &:not(:last-of-type) {
                margin-bottom: 10px;
            }

            @media (max-width: $screen-sm) {
                width: 100%;
            }
        }
    }

    &.not-stacked {
        > .radio-button {
            width: 150px;
            padding: 10px 40px 10px 49px;

            &:not(:last-of-type) {
                margin-right: 10px;
            }
        }
    }
}
