@import '../../../styles/variables';

.utility-item {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: $grid-gutter-width;

    .utility-button {
        cursor: pointer;
        background: none;
        font-weight: $font-weight-semibold;
        padding-left: $grid-gutter-width-threequarters;
        padding-right: 0;
        text-decoration: underline;
        line-height: 24px;
        text-transform: none;
        height: unset;

        &:hover,
        &:active,
        &:focus {
            color: $bga-blue-links !important;
            text-decoration-color: $bga-blue-links !important;
            box-shadow: none !important;
        }
    }

    .email-button {
        background: url(../../../assets/email.svg) no-repeat;
        background-position: left 0 top 8px;
    }

    .print-button {
        background: url(../../../assets/print.svg) no-repeat;
        background-position: left 0 top 6px;
    }
}
