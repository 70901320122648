@import '../../../styles/variables';

.laws-and-regs-tree-base {
    .lar-sm,
    .lar-md,
    .lar-lg {
        display: none;
    }

    ul.tree {
        li div.data * {
            line-height: 1.5;
        }

        li > div.data,
        li li > div.data {
            padding-bottom: 20px;
            padding-left: 10px;
            line-height: 1.5;
        }

        li > div.data > div.lar-ahecc,
        li li > div.data > div.lar-ahecc {
            text-align: left;
        }

        &.tree-mobile {
            .lar-sm {
                display: block;
            }

            li > div.data {
                padding-left: 5px;
            }
        }

        &.tree-tablet {
            .lar-md {
                display: block;
            }

            li > div.data {
                padding-left: 5px;
            }
        }

        &.tree-desktop {
            li > div.data,
            li li > div.data {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            li > div.data > div.lar-lg,
            li li > div.data > div.lar-lg {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }

            li > div.data > div.lar-lg > .lar-label,
            li li > div.data > div.lar-lg > .lar-label {
                padding-right: 15px;
            }

            li > div.data > div.lar-lg > div.lar-ahecc,
            li li > div.data > div.lar-lg > div.lar-ahecc {
                flex-shrink: 0;
                flex-grow: 0;
                flex-basis: 106px;
                text-align: left;
            }

            .lar-lg {
                display: block;
            }
        }
    }
}
