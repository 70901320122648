@import '../../styles/variables';

.event-speaker-container {
    align-items: center;
    margin-bottom: $grid-gutter-width-threequarters;

    @media screen and (min-width: $screen-md) {
        margin-bottom: $grid-gutter-width;
    }

    .speaker-image-container {
        display: flex;
        justify-content: center;

        @media screen and (min-width: $screen-md) {
            max-width: 200px;
        }
    }

    .speaker-image {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        object-fit: cover;
    }

    .speaker {
        border-left: 4px solid $bga-blue-black-light;
        padding-left: $grid-gutter-width-half;
    }
}
