@import '../../../styles/variables';

.filter-header {
    .filter-header-title {
        margin-top: -$grid-gutter-width-onequarter;
        position: relative;
        padding: 0;
        cursor: pointer;

        p {
            text-transform: none;
            padding: 0;
            display: inline-block;
        }
        svg {
            position: absolute;
            top: calc(50% - 10px);
            right: 0;
            width: 20px;
            height: 12.5px;
        }
        .svg-open {
            transform: rotate(180deg);
        }
    }

    &:focus {
        outline-color: $bga-blue-links;
    }
}
