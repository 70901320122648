@import '../../styles/variables';

.accordion-guide-pathway {
    .accordion-container {
        padding: 0 0 $grid-gutter-width 0;
    }

    .accordion-group-toggle.visible {
        display: flex;
    }

    .accordion-group-toggle {
        display: none;
        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center;
        border-bottom: 0.0625rem solid $bga-grey-lighter;
        justify-content: flex-end;

        button.accordion-group-toggle-content {
            border: 0;
            padding: 0;
            background: none;
            cursor: pointer;
            display: flex;
            justify-content: right;
            align-items: center;

            .accordion-group-toggle-inner {
                display: inline;
            }

            span {
                text-decoration: underline;
                min-width: 70px;
            }

            img {
                max-width: 16px;
                margin-left: $grid-gutter-width-onequarter;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    .accordion-guide-pathway {
        .accordion-group-toggle {
            button.accordion-group-toggle-content {
                span {
                    min-width: 60px;
                }
            }
        }
    }
}
