@import '../../styles/variables';

.footer-top,
.footer-bottom {
    color: $bga-white;
}

.footer-top {
    background-color: $bga-blue-links;

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: $bga-white;
    }

    ul {
        list-style-type: none;
        padding-left: 0;
    }

    .footer-links {
        li {
            margin-bottom: 1.5rem;

            a {
                background: url(../../assets/rightArrow.svg) no-repeat;
                background-position: left 0 top 3px;
                padding: 0 0 0 18px;
                line-height: 1.625rem;
                color: $bga-white;
                text-decoration: none;

                &:hover {
                    font-weight: 400;
                    text-decoration: underline;
                }

                &:focus-visible {
                    outline: 2px solid $bga-white;
                }
            }
        }
    }

    .footer-divider {
        padding: $grid-gutter-width-onequarter ($grid-gutter-width 0.5) ($grid-gutter-width);

        hr {
            display: block;
            border-top: solid 1px $bga-white;
            opacity: 1;
            margin: 0;
            padding: 0;
        }
    }

    .footer-social {
        padding: $grid-gutter-width-threequarters 0 0;

        @media (max-width: $screen-sm-max) {
            padding-left: $grid-gutter-width-half;
        }

        ul {
            display: flex;

            li {
                padding-right: 1rem;

                &:last-of-type {
                    padding-right: 0;
                }

                a {
                    background-color: $bga-blue-footer;
                    color: $bga-white;
                    border-radius: 50%;
                    width: 3rem;
                    height: 3rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: $bga-btn-boxshadow;

                    &:hover {
                        background-color: $bga-white;
                        color: $bga-blue-footer;
                    }

                    &:focus-visible {
                        background-color: $bga-white;
                        color: $bga-blue-footer;
                        outline: none;
                    }
                }

                svg {
                    width: 1.375rem;
                    height: 80%;
                }
            }
        }
    }
}

.footer-bottom {
    background-color: $bga-blue-footer;

    img {
        max-width: 21rem;
    }

    a {
        color: $bga-white;
        text-decoration: underline;

        &:visited,
        &:hover,
        &:active {
            color: $bga-white;
        }

        &:hover {
            font-weight: 400;
        }
        &:focus-visible {
            outline: 2px solid $bga-white;
        }
    }
}
