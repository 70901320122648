@import '../../styles/variables';

.feature-image-pathway-wrapper {
    margin-bottom: $grid-gutter-width;

    a.feature-image-pathway-anchor {
        text-decoration: none;
        display: block;
        height: 100%;
        background-color: $bga-blue-background-dark;
        color: $bga-white;
        border: none;

        .feature-image-pathway-card {
            height: 100%;
            min-height: 13rem;
            display: flex;
            flex-direction: row;

            .feature-image-pathway-card-content-left {
                flex: 1;
                width: 50%;
                display: flex;
                flex-direction: column;
                padding: $grid-gutter-width;
                align-content: space-between;
                color: $bga-white;

                .feature-image-pathway-card-text {
                    padding-bottom: $grid-gutter-width;

                    h3 {
                        font-size: 1.625rem;
                    }

                    h3,
                    h2,
                    h4 {
                        color: $bga-white;
                    }

                    .feature-image-pathway-card-description {
                        p:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .feature-image-pathway-footer {
                    margin-top: auto;
                    display: flex;
                    align-items: center;

                    .circle-background {
                        width: 48px;
                        height: 48px;
                        border-radius: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-shrink: 0;
                        z-index: 10;
                        background-color: $bga-white;
                    }
                }
            }

            .feature-image-pathway-card-content-right {
                padding: 0;
                display: block;
                width: 50%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &:active,
        &:focus {
            cursor: pointer;
            border: 2px solid $bga-blue-focus;
            background-color: $bga-blue-background-light;
            color: $bga-black;

            .feature-image-pathway-card {
                .feature-image-pathway-card-content-left {
                    .feature-image-pathway-card-text {
                        h2,
                        h3,
                        h4 {
                            text-decoration: underline;
                            color: $bga-blue-links;
                        }

                        .feature-image-pathway-card-description {
                            color: $bga-black;
                        }
                    }
                }
            }
        }

        &:hover {
            cursor: pointer;
            background-color: $bga-blue-links;
            box-shadow: none !important;

            .feature-image-pathway-card {
                .feature-image-pathway-card-content-left {
                    .feature-image-pathway-card-text {
                        h2,
                        h3,
                        h4 {
                            text-decoration: underline;
                            color: $bga-white;
                        }

                        .feature-image-pathway-card-description {
                            color: $bga-white;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    .feature-image-pathway-wrapper {
        a.feature-image-pathway-anchor {
            .feature-image-pathway-card {
                border-left-width: 8px;
                flex-direction: column-reverse;

                .feature-image-pathway-card-content-left,
                .feature-image-pathway-card-content-right {
                    width: 100%;
                }
            }
        }
    }
}
