﻿@import 'variables';

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: $bga-black;
    opacity: 0.5;
}

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;

    .modal,
    .modal-narrow {
        position: relative;
        margin: 1.75rem auto;
        padding: 2rem;
        background: $bga-white;
        border-top: solid 4px $bga-blue-light;
        border-radius: 3px;
        z-index: 100;

        .modal-close {
            position: absolute;
            top: $grid-gutter-width-half;
            right: $grid-gutter-width-half;
            cursor: pointer;
            z-index: 1;
        }

        ._loading_overlay_wrapper > ._loading_overlay_overlay {
            width: calc(100% + $grid-gutter-width);
            height: calc(100% + $grid-gutter-width);
            top: -$grid-gutter-width-half;
            left: -$grid-gutter-width-half;
        }
    }

    .modal {
        width: 700px;

        @media (max-width: $screen-sm-max) {
            width: auto;
        }
    }

    .modal-narrow {
        width: 400px;

        @media (max-width: $screen-xs-max) {
            width: auto;
        }
    }
}
