@import '../../styles/variables';
@import './ExpandCollapseTreeIcons';

/* List */
.tree-wrapper {
    .tree-container {
        @include _expandCollapseTreeIcons();

        ul.tree {
            li div.icon-click-overlay {
                position: absolute;
                left: 0;
                width: 28px;
                height: 28px;
                z-index: 3;
                cursor: pointer;
            }

            li li.is-expanded.has-children > div.data:after {
                display: initial;
                content: '';
                top: 0;
                left: 11px;
                height: 100%;
                width: 5px;
                position: absolute;
                background-image: url('../../assets/branch.svg');
                background-size: 100% 30px;
                background-position-y: 0;
                z-index: 1;
            }

            li li.is-expanded.has-children > div.data:after {
                top: 17px;
                left: -17px;
            }

            &.tree-tablet {
                li li.is-expanded.has-children > div.data:after {
                    height: 100%;
                    width: 3px;
                    background-image: url('../../assets/Tablet-branch.svg');
                    background-size: 150% 30px;
                    background-position-y: 0;
                    top: 13px;
                    left: -14px;
                }
            }

            &.tree-mobile {
                li li.is-expanded.has-children > div.data:after {
                    height: 100%;
                    width: 3px;
                    background-image: url('../../assets/mobile-branch.svg');
                    background-size: 100% 30px;
                    background-position-y: 0;
                    top: 12px;
                    left: -12px;
                }
            }
        }
    }
}
