@import '../../styles/variables';

.marketssearch-ahecc-container {
    .col {
        max-width: 67%;
    }
}
@media (max-width: 1024px) and (min-width: $screen-md) {
    .marketssearch-ahecc-container {
        .col {
            max-width: 80%;
        }
    }
}
@media (max-width: $screen-sm) {
    .marketssearch-ahecc-container {
        .col {
            max-width: 100%;
        }
    }
}
