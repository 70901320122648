@import '../../styles/variables';
@import '../../../../commonUI/src/styles/link';

.page-body {
    padding: $grid-gutter-width 0 0;

    ul:not(.left-nav-list):not(.tree):not(.sub-tree):not(.stepped-navigation) {
        margin: 0;
        padding: 0 0 $grid-gutter-width-onequarter ($grid-gutter-width * 1.5);

        li {
            margin-bottom: $grid-gutter-width-half;
            list-style-type: none;
            line-height: 1.75;
            text-indent: -22px;
            vertical-align: text-bottom;

            &::before {
                content: url(../../assets/Bullet-ellipse.svg);
                padding: 0 $grid-gutter-width-half 0 0;
                vertical-align: text-bottom;
            }

            &.tick {
                &::before {
                    content: url(../../assets/icon-tick-blue-black.svg);
                    padding: 0 $grid-gutter-width-onequarter 0 0;
                    vertical-align: middle;
                }
            }
        }

        ul {
            margin-top: $grid-gutter-width-half;
            padding: 0 0 0 ($grid-gutter-width * 1.5);

            li {
                &::before {
                    content: url(../../assets/Bullet-dash.svg);
                    padding: 0 14px 0 0;
                }
            }

            ul {
                padding: 0 0 0 ($grid-gutter-width * 1.5);

                li {
                    &::before {
                        content: url(../../assets/Bullet-ellipse-pale.svg);
                        padding: 0 $grid-gutter-width-half 0 0;
                    }
                }
            }
        }

        &.no-bullets {
            padding-left: 0;

            li {
                text-indent: 0;

                &::before {
                    display: none;
                }
            }
        }
    }

    ol {
        margin: 0;
        padding: 0 0 $grid-gutter-width-onequarter ($grid-gutter-width * 1.5);

        li {
            margin-bottom: $grid-gutter-width-half;
            line-height: 1.75;
            vertical-align: text-bottom;

            &::marker {
                font-weight: 600;
                color: $bga-blue-black-dark;
            }
        }

        ol {
            padding: 0 0 0 ($grid-gutter-width * 1.5);

            li {
                &::marker {
                    font-weight: 600;
                    color: $bga-blue-black-mid;
                }
            }

            ol {
                padding: 0 0 0 ($grid-gutter-width * 1.5);

                li {
                    &::marker {
                        font-weight: 400;
                    }
                }
            }
        }
    }

    a:not(.nav-anchor) {
        @include _link;
    }
}

@media (max-width: $screen-xs-max) {
    .page-body {
        ul:not(.left-nav-list):not(.tree):not(.sub-tree):not(.stepped-navigation) {
            padding: 0 0 $grid-gutter-width-onequarter $grid-gutter-width;

            li {
                text-indent: -12px;

                &::before {
                    padding: 0 10px 0 0;
                }
            }

            ul {
                padding: 0 0 0 $grid-gutter-width;

                li {
                    &::before {
                        padding: 0 $grid-gutter-width-onequarter 0 0;
                    }
                }

                ul {
                    padding: 0 0 0 $grid-gutter-width;

                    li {
                        &::before {
                            padding: 0 10px 0 0;
                        }
                    }
                }
            }
        }

        ol {
            padding: 0 0 $grid-gutter-width-onequarter $grid-gutter-width;

            ol {
                padding: 0 0 0 $grid-gutter-width;

                ol {
                    padding: 0 0 0 $grid-gutter-width;
                }
            }
        }
    }
}
