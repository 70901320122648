@import '../../../styles/variables';

a.inline-pathway {
    text-decoration: none;
    color: $bga-black !important;
    border: 0 !important;
    display: block;
    margin-bottom: $grid-gutter-width-onequarter * 0.5;

    .inline-container {
        padding: $grid-gutter-width-threequarters;
        border-left: 8px solid $bga-blue-border;
        background: $bga-blue-background-extralight;

        .inline-wrapper {
            line-height: 16px;
        }

        .description {
            margin-bottom: $grid-gutter-width-half;

            * {
                text-decoration: underline;
                text-decoration-color: $bga-blue-links;
                color: $bga-blue-links;
                font-weight: $font-weight-semibold;
                line-height: 28px;
                margin: 0;
            }
        }

        .icon-link {
            line-height: 24.5px;
            font-size: $font-size-base * 0.875;
            gap: 0;

            .icon {
                padding-right: $grid-gutter-width-onequarter * 1.5;
            }

            .text {
                margin-left: $grid-gutter-width-onequarter;
            }
        }
    }

    &:hover .inline-container {
        background-color: $bga-blue-background-light;
        color: $bga-black !important;
    }

    &:focus .inline-container {
        background-color: $bga-blue-background-light;
        outline: $bga-blue-background-dark auto 2px !important;
        color: $bga-black !important;
    }
}
