@import '../../styles/variables';

.pros-and-cons {
    display: flex;
    flex-direction: row;

    .pros {
        padding: $grid-gutter-width-threequarters;
        border-left: 8px solid $bga-green-highlight-mid;
        margin-bottom: $grid-gutter-width;
        background-color: $bga-green-highlight-light;
        width: 50%;

        ul {
            padding-left: $grid-gutter-width-threequarters;
            padding-bottom: 0;
        }

        .pro-icon-container {
            justify-content: center;
            display: flex;

            .pro-icon {
                border-radius: 50%;
                height: 56px;
                width: 56px;
                text-align: center;
                background-color: $bga-white;
                margin-bottom: $grid-gutter-width-half;

                .pro-svg-wrapper {
                    display: inline-block;
                    margin-top: $grid-gutter-width-onequarter;
                }
            }
        }
    }

    .cons {
        padding: $grid-gutter-width-threequarters;
        border-left: 8px solid $bga-red-fail-mid;
        margin-bottom: $grid-gutter-width;
        background-color: $bga-red-fail-light;
        width: 50%;

        ul {
            padding-left: $grid-gutter-width-threequarters;
            padding-bottom: 0;
        }

        .cons-icon-container {
            justify-content: center;
            display: flex;
            margin-bottom: $grid-gutter-width-half;

            .con-icon {
                border-radius: 50%;
                height: 56px;
                width: 56px;
                text-align: center;
                background-color: $bga-white;

                .con-svg-wrapper {
                    display: inline-block;
                    margin-top: $grid-gutter-width * 0.4375;
                }
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    .pros-and-cons {
        flex-direction: column;

        .pros,
        .cons {
            width: 100%;
        }
    }
}
