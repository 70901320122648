@import '../../styles/variables';

.event-results-container {
    .event-results-container-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: center;
        width: auto !important;

        .list-block {
            display: flex;
            flex-direction: column;
            padding-bottom: $grid-gutter-width;
        }
    }
}
