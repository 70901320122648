@import '../../styles/variables';

.information-notification {
    color: $bga-black;
    margin-bottom: $grid-gutter-width;
    background: $bga-blue-background-extralight;
    border-left: 8px solid $bga-blue-border;

    .information-notification-content {
        display: flex;
        gap: $grid-gutter-width-threequarters;
        padding: $grid-gutter-width-threequarters;
        padding-right: $grid-gutter-width-threequarters * 2;

        .information-icon {
            flex: none;
        }
    }

    .description {
        color: $bga-black;
        margin: 0;
    }
}

@media (max-width: $screen-xs-max) {
    .information-notification {
        .information-icon {
            align-self: center;
        }

        .information-notification-content {
            flex-direction: column;
            gap: $grid-gutter-width-half;
            padding-right: $grid-gutter-width-threequarters;
        }
    }
}
