﻿@import 'variables';

@mixin bodyText() {
    p,
    div {
        font-size: 1rem;
        font-weight: $font-weight-normal;
        line-height: 28px;
        letter-spacing: 0;

        &.mini {
            font-size: 0.875rem;
            line-height: 24.5px;
        }

        &.label-search-cards {
            font-size: 0.75rem;
            font-weight: $font-weight-semibold;
            line-height: 18px;
        }

        &.results-count,
        &.results-message {
            font-size: 1.75rem;
            color: $bga-blue-black-dark;
            line-height: 42px;
        }

        &.no-space-under-last-child {
            > div > *:last-child,
            *:last-child {
                margin-bottom: 0 !important;
            }
        }
    }

    label,
    legend {
        font-size: 1.125rem;
        line-height: 27px;

        &.mini {
            font-size: 0.875rem;
        }

        &.field-label {
            font-size: $h3-font-size;
            font-weight: $font-weight-semibold;
            margin-bottom: $grid-gutter-width-half;
            line-height: 33px;
        }
    }

    .semi-bold {
        font-weight: $font-weight-semibold;
    }

    @media (max-width: $screen-sm-max) {
        p,
        div {
            &.results-count,
            &.results-message {
                font-size: 1rem;
                line-height: 28px;
            }
        }

        label,
        legend {
            font-size: 0.875rem;
            line-height: 21px;
        }
    }
}
