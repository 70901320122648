@import '../../../styles/variables';

.com-multi-select-ui-item {
    display: inline-block;
    margin-left: 0;
    text-transform: none;
    margin-bottom: $grid-gutter-width-onequarter;
    border: solid 1px $bga-black;
    font-size: 0.8rem;
    margin-right: $grid-gutter-width-onequarter * 0.5;
    padding: 2px $grid-gutter-width-half;
    border-radius: 16px;
    text-align: center;
    line-height: 22px;
    color: $bga-black;
    background-color: rgb(238, 237, 234);

    .close-text {
        margin-top: 0;
        margin-left: 10px;
    }

    &:focus {
        outline-color: $bga-blue;
    }
}
