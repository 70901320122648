@import 'variables';

@mixin mask-properties {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: 50% 50%;
    mask-position: 50% 50%;
}

@mixin icons() {
    button {
        &.refresh-icon {
            &::after {
                position: relative;
                top: 3px;
                margin-left: $grid-gutter-width-onequarter;
                height: 16px;
                width: 16px;
                display: inline-block;
                content: ' ';
                @include mask-properties;
                -webkit-mask-image: url(../assets/refresh.svg);
                mask-image: url(../assets/refresh.svg);
                background-color: $bga-blue-links;
            }

            &:hover::after,
            &:focus::after {
                background-color: $bga-blue-footer;
            }
        }

        &.link-icon {
            &::after {
                position: relative;
                top: 3px;
                margin-left: $grid-gutter-width-onequarter;
                height: 16px;
                width: 32px;
                display: inline-block;
                content: ' ';
                @include mask-properties;
                -webkit-mask-image: url(../assets/link.svg);
                mask-image: url(../assets/link.svg);
                background-color: $bga-black;
            }

            &:hover::after,
            &:focus::after {
                background-color: $bga-white;
            }
        }

        &.search-icon {
            &::after {
                position: relative;
                top: 3px;
                height: 24px;
                width: 24px;
                display: inline-block;
                content: ' ';
                @include mask-properties;
                -webkit-mask-image: url(../assets/icon-search.svg);
                mask-image: url(../assets/icon-search.svg);
                background-color: $bga-white;
            }

            &:hover::after {
                background-color: $bga-white !important;
            }

            &:focus::after {
                background-color: $bga-blue-background-dark;
            }
        }

        &.arrow-down-icon {
            &::after {
                background-color: $bga-blue-links;
            }

            &:hover::after,
            &:focus::after {
                background-color: $bga-blue-footer;
            }
        }

        &.back-to-top-icon {
            padding: $grid-gutter-width-onequarter * 1.5 $grid-gutter-width-half;
            min-width: 32px;
            min-height: 32px;

            &::before {
                position: relative;
                top: 4px;
                margin: 0;
                height: 24px;
                width: 24px;
                display: inline-block;
                content: ' ';
                @include mask-properties;
                -webkit-mask-image: url(../assets/back-to-top.svg);
                mask-image: url(../assets/back-to-top.svg);
                background-color: $bga-black;
            }

            &:focus,
            &:hover {
                &::before {
                    background-color: $bga-white;
                }
            }

            @media screen and (max-width: $screen-xs-max) {
                padding: $grid-gutter-width-onequarter * 0.75 $grid-gutter-width-onequarter * 1.25;
            }
        }

        &.person-icon {
            &::before {
                margin-right: $grid-gutter-width-onequarter;
                height: 32px;
                width: 32px;
                content: ' ';
                display: inline-block;
                border: 2px solid $bga-white;
                border-radius: 50%;
                background-color: $bga-white;
                background-image: url(../assets/person.svg);
                background-size: 28px 28px;
                background-repeat: no-repeat;
                vertical-align: middle;
            }
        }
    }

    :not(button) {
        &.thumbs-up-icon,
        &.thumbs-down-icon,
        &.signout-icon {
            height: 32px;
            width: 32px;
            display: inline-block;
            content: ' ';
            @include mask-properties;
            background-color: $bga-blue-black-dark;
        }

        &.thumbs-up-icon {
            -webkit-mask-image: url(../assets/thumbsUp.svg);
            mask-image: url(../assets/thumbsUp.svg);
        }

        &.thumbs-down-icon {
            -webkit-mask-image: url(../assets/thumbsDown.svg);
            mask-image: url(../assets/thumbsDown.svg);
        }

        &.signout-icon {
            -webkit-mask-image: url(../assets/logout.svg);
            mask-image: url(../assets/logout.svg);
        }

        &.case-study-icon,
        &.light-bulb-icon {
            height: 40px;
            width: 40px;
            display: inline-block;
            content: ' ';
            @include mask-properties;
            background-color: $bga-blue-black-dark;

            @media (max-width: $screen-xs-max) {
                height: 30px;
                width: 30px;
            }
        }

        &.case-study-icon {
            -webkit-mask-image: url(../assets/icon-casestudy-blueblack.svg);
            mask-image: url(../assets/icon-casestudy-blueblack.svg);
        }

        &.light-bulb-icon {
            -webkit-mask-image: url(../assets/icon-lightbulb-blueblack.svg);
            mask-image: url(../assets/icon-lightbulb-blueblack.svg);
        }

        &.arrow-right-icon,
        &.arrow-right-black-icon,
        &.arrow-right-white-icon,
        &.arrow-right-blue-icon {
            height: 15.14px;
            width: 16px;
            display: inline-block;
            content: ' ';
            @include mask-properties;
            -webkit-mask-image: url(../assets/iconArrowRight.svg);
            mask-image: url(../assets/iconArrowRight.svg);
        }

        &.arrow-right-black-icon {
            background-color: $bga-black;
        }

        &.arrow-right-white-icon {
            background-color: $bga-white;
        }

        &.arrow-right-blue-icon {
            background-color: $bga-blue-links;
        }

        &.arrow-down-icon {
            &::after {
                background-color: $bga-black;
            }
        }

        &.information-icon {
            height: 44px;
            width: 44px;
            display: inline-block;
            background-size: contain !important;
            background: url(../assets/information.svg) 0 0 no-repeat padding-box;
        }

        &.disclaimer-icon {
            height: 20px;
            width: 20px;
            display: inline-block;
            background-size: contain !important;
            background: url(../assets/disclaimer.svg) 0 0 no-repeat padding-box;
        }

        &.close-icon,
        &.close-mini-icon {
            display: inline-block;
            content: ' ';
            @include mask-properties;
            -webkit-mask-image: url(../assets/close.svg);
            mask-image: url(../assets/close.svg);
        }

        &.close-icon {
            height: 22px;
            width: 22px;
            background-color: $bga-blue-links;
        }

        &.close-mini-icon {
            height: 10px;
            width: 10px;
            background-color: $bga-black;
        }

        &.success-tick-icon {
            height: 42px;
            width: 42px;
            display: inline-block;
            background-size: contain !important;
            background: url(../assets/icon-notification-circle-success.svg) 0 0 no-repeat padding-box;
        }

        &.high-priority-icon {
            height: 28px;
            width: 28px;
            border-radius: 50%;
            display: inline-block;
            background-size: contain !important;
            background: url(../assets/highPriority.svg) 0 0 no-repeat padding-box;
        }

        // Event icons
        .event-icon,
        .event-available-icon,
        .event-completed-icon,
        .booking-closed-icon {
            height: 21px;
            width: 18px;
            display: inline-block;
            content: ' ';
            @include mask-properties;
            background-color: $bga-black;
        }

        .event-icon {
            -webkit-mask-image: url(../assets/event.svg);
            mask-image: url(../assets/event.svg);
        }

        .event-available-icon {
            -webkit-mask-image: url(../assets/event_available.svg);
            mask-image: url(../assets/event_available.svg);
        }

        .event-completed-icon,
        .booking-closed-icon {
            -webkit-mask-image: url(../assets/event_booking_closed.svg);
            mask-image: url(../assets/event_booking_closed.svg);
        }
    }

    .arrow-down-icon {
        &::after {
            margin-left: $grid-gutter-width-onequarter;
            height: 10px;
            width: 16px;
            display: inline-block;
            content: ' ';
            @include mask-properties;
            -webkit-mask-image: url(../assets/chevronDown333.svg);
            mask-image: url(../assets/chevronDown333.svg);
            transition: 0.1s;
        }

        &.expanded {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    .download-icon::before,
    .pin-icon::before,
    .calendar-icon::before,
    .calendar-icon-orange::before,
    .calendar-icon-blue::before {
        position: relative;
        top: 3px;
        margin-right: $grid-gutter-width-onequarter;
        display: inline-block;
        content: ' ';
        @include mask-properties;
    }

    .download-icon::before {
        height: 16px;
        width: 16px;
        -webkit-mask-image: url(../assets/download.svg);
        mask-image: url(../assets/download.svg);
        background-color: $bga-black;
    }

    .pin-icon::before {
        height: 16px;
        width: 12px;
        -webkit-mask-image: url(../assets/pin.svg);
        mask-image: url(../assets/pin.svg);
        background-color: $bga-black;
    }

    .calendar-icon::before,
    .calendar-icon-orange::before,
    .calendar-icon-dark-blue::before {
        height: 18px;
        width: 16px;
        -webkit-mask-image: url(../assets/calendar.svg);
        mask-image: url(../assets/calendar.svg);
    }

    .calendar-icon-orange::before {
        background-color: $bga-orange-highlight-shadow;
    }

    .calendar-icon-dark-blue::before {
        background-color: $bga-blue-black-dark;
    }

    // Guide pathway icons
    .tool-icon::before,
    .quiz-icon::before,
    .template-icon::before,
    .tutorial-icon::before,
    .guide-icon::before,
    .options-icon::before,
    .success-story-icon::before,
    .organisation-icon::before,
    .news-icon::before,
    .blog-icon::before,
    .checklist-icon::before {
        position: relative;
        top: 3px;
        margin-right: $grid-gutter-width-onequarter;
        height: 18px;
        width: 16px;
        display: inline-block;
        content: ' ';
        @include mask-properties;
        background-color: $bga-black;
    }

    .tool-icon::before {
        -webkit-mask-image: url(../assets/construction.svg);
        mask-image: url(../assets/construction.svg);
    }

    .quiz-icon::before {
        -webkit-mask-image: url(../assets/quiz.svg);
        mask-image: url(../assets/quiz.svg);
    }

    .template-icon::before {
        -webkit-mask-image: url(../assets/post_add.svg);
        mask-image: url(../assets/post_add.svg);
    }

    .tutorial-icon::before {
        -webkit-mask-image: url(../assets/format_list_numbered.svg);
        mask-image: url(../assets/format_list_numbered.svg);
    }

    .guide-icon::before {
        -webkit-mask-image: url(../assets/hiking.svg);
        mask-image: url(../assets/hiking.svg);
    }

    .options-icon::before {
        -webkit-mask-image: url(../assets/signpost.svg);
        mask-image: url(../assets/signpost.svg);
    }

    .success-story-icon::before {
        -webkit-mask-image: url(../assets/auto_awesome.svg);
        mask-image: url(../assets/auto_awesome.svg);
    }

    .organisation-icon::before {
        -webkit-mask-image: url(../assets/apartment.svg);
        mask-image: url(../assets/apartment.svg);
    }

    .news-icon::before {
        -webkit-mask-image: url(../assets/menu_book.svg);
        mask-image: url(../assets/menu_book.svg);
    }

    .blog-icon::before {
        -webkit-mask-image: url(../assets/comment.svg);
        mask-image: url(../assets/comment.svg);
    }

    .checklist-icon::before {
        height: 18px;
        width: 24px;
        -webkit-mask-image: url(../assets/checklist.svg);
        mask-image: url(../assets/checklist.svg);
    }
}
