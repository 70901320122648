@import 'variables';

@mixin _link() {
    background: transparent;
    min-height: unset;
    min-width: unset;
    padding: 0;
    color: $bga-blue-links;
    line-height: 28px;
    text-decoration: underline;
    text-decoration-color: $bga-blue-links;
    letter-spacing: 0.2px;
    border: 2px solid transparent;
    transition-delay: 0s, 0s;
    transition-duration: 0.25s, 0.25s;
    transition-property: box-shadow;
    transition-timing-function: ease, ease;
    font-weight: $font-weight-semibold;
    cursor: pointer;

    &:focus {
        border: 2px solid $bga-blue-focus;
        border-radius: 4px;
        box-shadow: inset 0 -500px 0 $bga-blue-hover;
        color: $bga-blue-footer !important;
        text-decoration-color: $bga-blue-footer !important;
        outline: none;
    }

    &:not(:disabled):hover,
    &:not(:disabled):active {
        box-shadow: inset 0 -500px 0 $bga-blue-hover;
        color: $bga-blue-footer;
        text-decoration-color: $bga-blue-footer;
    }

    &:focus:hover {
        border: 2px solid $bga-blue-focus;
        color: $bga-blue-footer;
        text-decoration-color: $bga-blue-footer;
    }

    &:visited {
        color: $bga-purple-links-visited;
        text-decoration-color: $bga-purple-links-visited;
    }
}
