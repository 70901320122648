@import '../../styles/variables';

.inline-link {
    margin-bottom: $grid-gutter-width-half;

    a {
        text-decoration: underline;
        font-weight: bold;

        img {
            padding-right: $grid-gutter-width-onequarter;
        }
    }
}
