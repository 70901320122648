@import '../../styles/variables';

.homepage-header-wrapper-top:before {
    background: $bga-white;
    content: '';
    height: 56px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.homepage-header-wrapper {
    background: linear-gradient(0deg, $bga-light-blue-grey, $bga-carolina-blue) 0 0 no-repeat !important;
    padding-bottom: $grid-gutter-width * 1.5;
    position: relative;
    box-sizing: border-box;
    display: block;

    .homepage-header {
        min-height: 300px;
        color: $bga-white;
        padding-bottom: 0 !important;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        .content-header {
            position: relative;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            min-height: 20vh;
            background-color: $bga-blue-background-dark !important;
            padding: 0;
            z-index: 2;

            .text-container {
                padding: $grid-gutter-width $grid-gutter-width $grid-gutter-width-double $grid-gutter-width;

                h1 {
                    font-size: $font-size-base * 3.375;
                    line-height: 81px;
                    color: $bga-white;
                }

                .header-label {
                    font-size: $font-size-base * 0.75;
                    line-height: 20px;
                    text-transform: uppercase;
                }

                .header-intro {
                    font-size: $font-size-base * 1.5;
                    line-height: 36px;
                }
            }

            .page-header-backgroundIcon {
                display: block;
                max-height: none;

                img {
                    overflow: hidden;
                    width: 100%;
                    height: 100%;
                    max-width: 100%;
                    object-fit: cover;
                    aspect-ratio: 9/6 auto;
                }
            }
        }
    }
}

@media (max-width: $screen-md-max) {
    .homepage-header-wrapper {
        .homepage-header {
            min-height: 200px;

            .content-header {
                .text-container {
                    padding: $grid-gutter-width-threequarters;

                    h1 {
                        font-size: $h1-font-size;
                        line-height: 66px;
                    }

                    .header-intro {
                        font-size: $font-size-base * 1.125;
                        line-height: 28px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    .homepage-header-wrapper {
        .homepage-header {
            .content-header {
                .text-container {
                    h1 {
                        font-size: $font-size-base * 2.25;
                        line-height: 54px;
                    }
                }

                .page-header-backgroundIcon {
                    max-height: 17rem;
                }
            }
        }
    }
}

@media (max-width: $screen-below-xs) {
    .homepage-header-wrapper {
        .homepage-header {
            .content-header {
                .page-header-backgroundIcon {
                    max-height: 11rem;
                }
            }
        }
    }
}
