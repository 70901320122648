@import '../../../styles/variables';

.news-pathway-tile-wrapper {
    margin-bottom: $grid-gutter-width;
    display: block;

    a[href*="//"]
    {
        &:after {
            padding-left: 0;
            content: '';
        }
    }

    a {
        border-bottom: 0;
        font-weight: normal;
        text-decoration: none !important;
        display: block;
        height: 100%;
        background-color: $bga-white;

        .news-pathway-tile-card {
            height: 100%;
            background-color: $bga-white;
            color: $bga-black;
            position: relative;
            border: 1px solid $bga-carolina-blue;
            border-left: 4px solid;
            border-left-color: $bga-blue-background-dark;

            .news-pathway-tile-card-content {
                padding-bottom: 0;

                .news-pathway-tile-card-category {
                    text-align: left;
                    color: $bga-black;
                    margin-bottom: $grid-gutter-width-threequarters;
                }

                .news-pathway-tile-card-title {
                    margin-bottom: $grid-gutter-width-threequarters;
                    text-decoration: underline;
                    color: $bga-blue-links;
                    line-height: 27px !important;
                    font-size: $font-size-base * 1.125 !important;
                }
            }
            .news-pathway-tile-card-footer {
                position: absolute;
                bottom: 24px;
                display: flex;
                gap: $grid-gutter-width;
                width: 100%;
                align-items: center;
                color: $bga-black;
            }

            .news-pathway-tile-card-image {
                width: auto;
                max-height: 100%;
                display: flex;
                justify-content: flex-start;
                align-content: center;
                img {
                    width: 100%;
                    height: 240px;
                    object-fit: cover;
                }
            }
        }

        &:hover,
        &:active,
        &:focus {
            cursor: pointer;
            box-shadow: none !important;

            .news-pathway-tile-card {
                background: $bga-blue-background-extralight;

                .tag {
                    background: $bga-white !important;
                }
            }
        }
    }
}
