@import '../../../styles/variables';

.narrow-down-search-tree {
    ul.tree {
        .link {
            padding-left: 0;
            padding-right: 0;
            text-decoration: none;
            letter-spacing: 0;

            .content {
                font-weight: 600;
                text-decoration: underline;
                text-underline-offset: 4px;
            }
        }

        li.has-selection .link .content {
            font-weight: 600;
        }

        li.is-focused {
            & > div.data .link {
                .content {
                    color: $bga-black;
                    text-decoration: none;
                    font-weight: bolder;
                }
            }
        }

        li.has-selection:before {
            display: initial;
            content: '';
            top: 0;
            left: 0;
            height: 28px;
            width: 28px;
            position: absolute;
            background-image: url('../../../assets/Ellipse-border.svg');
            background-size: 28px;
            background-position-y: 0;
            z-index: 2;
            padding-top: 0;
        }

        &.tree-tablet li.has-selection:before {
            height: 24px;
            width: 24px;
            background-image: url('../../../assets/Tablet-ellipse-border.svg');
            background-size: 24px;
            background-position-y: 0;
        }

        &.tree-mobile li.has-selection:before {
            height: 20px;
            width: 20px;
            background-image: url('../../../assets/Mobile-ellipse-border.svg');
            background-size: 20px;
            background-position-y: 0;
        }

        li.is-focused:before {
            display: initial;
            content: '';
            top: 0;
            left: 0;
            height: 28px;
            width: 28px;
            position: absolute;
            background-image: url('../../../assets/Ellipse-filled.svg');
            background-size: 28px;
            background-position-y: 0;
            z-index: 2;
        }

        &.tree-tablet li.is-focused:before {
            height: 24px;
            width: 24px;
            background-image: url('../../../assets/Tablet-ellipse-filled.svg');
            background-size: 24px;
            background-position-y: 0;
        }

        &.tree-mobile li.is-focused:before {
            height: 20px;
            width: 20px;
            background-image: url('../../../assets/Mobile-ellipse-filled.svg');
            background-size: 20px;
            background-position-y: 0;
        }
    }
}
