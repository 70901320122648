@import '../../styles/variables';

.download-information {
    box-shadow: 0 0.125rem 0.375rem 0 rgba(0, 0, 0, 0.1);
    padding: $grid-gutter-width-threequarters;
    background: $bga-yellow-highlight-light 0% 0% no-repeat padding-box;
    border: 0;
    border-left: 8px solid $bga-yellow-highlight-dark;
    margin-bottom: $grid-gutter-width;

    .description {
        padding: 0;
        margin-bottom: $grid-gutter-width-threequarters;
    }

    .download-item {
        border: 0;
        display: block !important;
        background: $bga-white 0% 0% no-repeat padding-box !important;
        padding: $grid-gutter-width-half !important;
        margin: 0 !important;
        text-decoration: none !important;

        * {
            margin: 0;
        }

        &:disabled {
            opacity: 1;
        }

        [data-testid='overlay'] {
            position: relative;
        }

        &:not(:last-of-type) {
            margin-bottom: $grid-gutter-width-onequarter * 0.5 !important;
        }

        &:not(:disabled):focus,
        &:not(:disabled):hover {
            background: $bga-blue-background-extralight 0% 0% no-repeat padding-box !important;
        }

        &:not(:disabled):active {
            background: $bga-blue-background-light 0% 0% no-repeat padding-box !important;
        }

        &:not(:disabled):active,
        &:not(:disabled):focus,
        &:not(:disabled):hover {
            box-shadow: none !important;
            border-radius: 4px;

            .file-name {
                text-decoration: underline;
            }
        }

        .file-name {
            color: $bga-blue-links;
            font-weight: $font-weight-semibold;
            margin-bottom: $grid-gutter-width-half !important;
        }

        .file-type {
            color: $bga-black;
            font-size: $font-size-base * 0.875;
            line-height: 24.5px;
            text-transform: uppercase;
            margin: 0;
        }
    }
}
