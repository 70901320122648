@import '../../styles/variables';

.search-results-header {
    background: $bga-blue-background-light;
    padding: $grid-gutter-width-threequarters 0;

    > div {
        position: relative;
    }

    .site-search__input-container {
        .site-search__input {
            height: 56px;
        }
    }

    .search-btn {
        button {
            min-height: 56px !important;
        }

        .clear-text-container {
            height: 56px;
        }
    }

    @media (min-width: $screen-sm-max) {
        padding: $grid-gutter-width-threequarters * 2 0;
    }
}
