@import 'variables';

.results-count-header {
    font-size: 1.75rem;
    font-weight: normal;
    padding-top: 0;
    padding-bottom: 0;

    @media (max-width: $screen-sm-max) {
        font-size: 1rem;
        line-height: 28px;
    }
}

div.filter-header-title > p.label {
    font-size: $h3-font-size;
    font-weight: $font-weight-semibold;
    line-height: 27px;
    margin-bottom: $grid-gutter-width-onequarter;
    margin-top: $grid-gutter-width * 0.125;
}

.filter-main-panel {
    .filter-main-panel-separator {
        width: 100%;
        border: 0;
        margin: 0;
        text-align: left;
        border-top: 0.125rem solid $bga-grey;
        padding-bottom: $grid-gutter-width * 0.625;
    }

    padding-bottom: $grid-gutter-width-threequarters;
}

.pagination-links-container {
    padding-top: $grid-gutter-width-onequarter;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto $grid-gutter-width-half;
}
