@import '../../styles/variables';

.tooltip-text {
    padding-bottom: $grid-gutter-width-onequarter;

    .tooltip-text-dropdown-tile {
        display: flex;
        align-items: center;
        align-content: baseline;
        justify-content: flex-start;
        color: $bga-blue-light;
        text-decoration: underline;
        cursor: pointer;
        padding-bottom: $grid-gutter-width-onequarter;

        p {
            padding: 0;
            display: inline-block;
        }
        span {
            padding-left: $grid-gutter-width-onequarter;
        }
        .svg-container {
            align-items: center;
            justify-content: center;
            .svg-open {
                transform: rotate(90deg);
            }
        }
    }

    .tooltip-text-dropdown-content {
        display: none;
        border-left: solid 2px $bga-blue-light;
        margin: 0 0 $grid-gutter-width-onequarter 2px;

        box-sizing: border-box;

        a {
            text-decoration: underline;
        }

        p {
            font-size: 14px;
        }
    }
    .active {
        display: block;
        padding-left: $grid-gutter-width-onequarter;
    }

    &:focus {
        outline-color: $bga-blue;
    }
}
