@import '../../styles/variables';

/* List */
.tree-container {
    ul.tree,
    ul.tree ul {
        list-style: none;
        padding-left: 0;

        .content {
            text-align: left;
        }
    }

    ul.tree ul {
        padding-left: 10px;
        position: relative;
    }

    ul.tree ul:before {
        display: initial;
        content: '';
        top: 2px;
        left: -5px;
        height: 26px;
        width: 5px;
        position: absolute;
        transform: rotate(90deg);
        background-image: url(../../assets/branch.svg);
        background-size: 100%;
    }

    ul.tree ul {
        padding-left: 6px;
        position: relative;
        margin-top: 0;
    }

    ul.tree.tree-tablet ul,
    ul.tree.tree-mobile ul {
        padding-left: 2px;
        position: relative;
    }

    ul.tree.tree-tablet ul:before {
        top: 5px;
        left: -6px;
        height: 13px;
        width: 3px;
        background-image: url(../../assets/Tablet-branch.svg);
        background-size: initial;
    }

    ul.tree.tree-mobile ul:before {
        top: 5px;
        left: -5px;
        height: 13px;
        width: 2px;
        background-image: url(../../assets/mobile-branch.svg);
        background-size: initial;
    }

    ul.tree li.has-selection > .data .content {
        font-weight: bolder;
    }

    /* tree node */

    ul.tree li,
    ul.tree li li {
        position: relative;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 28px;
        margin-bottom: 0;
        text-indent: 0;
    }

    ul.tree.tree-tablet li,
    ul.tree.tree-tablet li li {
        padding-left: $grid-gutter-width-threequarters;
    }

    ul.tree.tree-mobile li,
    ul.tree.tree-mobile li li {
        padding-left: 20px;
    }

    /* tree node - bullet point */

    ul.tree li:before,
    ul.tree li li:before {
        display: initial;
        content: '';
        top: 0;
        left: 0;
        height: 28px;
        width: 28px;
        position: absolute;
        background-image: url('../../assets/Ellipse-border.svg');
        background-size: 28px;
        background-position-y: 0;
        z-index: 2;
        padding-top: 0;
    }

    ul.tree.tree-tablet li:before,
    ul.tree.tree-tablet li li:before {
        height: 24px;
        width: 24px;
        background-image: url('../../assets/Tablet-ellipse-border.svg');
        background-size: 24px;
        background-position-y: 0;
    }

    ul.tree.tree-mobile li:before,
    ul.tree.tree-mobile li li:before {
        height: 20px;
        width: 20px;
        background-image: url('../../assets/Mobile-ellipse-border.svg');
        background-size: 20px;
        background-position-y: 0;
    }

    ul.tree li.has-see-more.expanded {
        display: none;
    }

    ul.tree li.has-see-more:before {
        display: initial;
        content: '';
        top: 0;
        left: 0;
        height: 28px;
        width: 28px;
        position: absolute;
        background-image: url('../../assets/Ellipse-open.png');
        background-size: 28px;
        background-position-y: -4px;
        z-index: 2;
        cursor: pointer;
    }

    ul.tree li.has-see-less {
        display: none;
    }

    ul.tree li.has-see-less.expanded {
        display: list-item;
    }

    ul.tree li.has-contractibility {
        display: none;
    }

    ul.tree li.has-contractibility.force-show {
        display: list-item;
    }

    ul.tree li.has-see-less:before {
        display: initial;
        content: '';
        top: 0;
        left: 0;
        height: 27px;
        width: 27px;
        position: absolute;
        background-image: url('../../assets/Ellipse-close.svg');
        background-size: 27px;
        background-position-y: -15px;
        z-index: 2;
        cursor: pointer;
    }

    ul.tree li.has-selection:before {
        display: initial;
        content: '';
        top: 0;
        left: 0;
        height: 28px;
        width: 28px;
        position: absolute;
        background-image: url('../../assets/Ellipse-filled.svg');
        background-size: 28px;
        background-position-y: 0;
        z-index: 2;
    }

    ul.tree.tree-tablet li.has-selection:before {
        height: 24px;
        width: 24px;
        background-image: url('../../assets/Tablet-ellipse-filled.svg');
        background-size: 24px;
        background-position-y: 0;
    }

    ul.tree.tree-mobile li.has-selection:before {
        height: 20px;
        width: 20px;
        background-image: url('../../assets/Mobile-ellipse-filled.svg');
        background-size: 20px;
        background-position-y: 0;
    }

    /* tree node - connecter lines */

    ul.tree li > div.data,
    ul.tree ul > li > div.data {
        position: relative;
    }

    ul.tree.tree-root > li.sole-root > div.data:after,
    ul.tree li.is-sole-parent > div.data:after,
    ul.tree li.has-next-sibling-data:after,
    ul.tree li:not(:last-of-type).has-next-sibling:after,
    ul.tree li.force-show:after {
        display: initial;
        content: '';
        top: 0;
        left: 11px;
        height: 100%;
        width: 5px;
        position: absolute;
        background-image: url('../../assets/branch.svg');
        background-size: 100% 30px;
        background-position-y: 0;
        z-index: 1;
    }

    ul.tree li.sole-root > div.data:after,
    ul.tree li.is-sibling > div.data:after,
    ul.tree li.is-sole-parent > div.data:after,
    ul.tree.tree-root > li.sole-root > div.data:after {
        top: 17px;
        left: -17px;
    }

    ul.tree.tree-tablet li.sole-root > div.data:after,
    ul.tree.tree-tablet li.is-sole-parent > div.data:after,
    ul.tree.tree-tablet li.has-next-sibling:after,
    ul.tree.tree-tablet li:not(:last-of-type).has-next-sibling:after,
    ul.tree.tree-tablet li.force-show:after {
        height: 100%;
        width: 3px;
        left: 10px;
        background-image: url('../../assets/Tablet-branch.svg');
        background-size: 150% 30px;
        background-position-y: 0;
    }

    ul.tree.tree-tablet li.sole-root > div.data:after,
    ul.tree.tree-tablet li.is-sole-parent > div.data:after,
    ul.tree.tree-tablet li.is-sibling > div.data:after {
        top: 13px;
        left: -14px;
    }

    ul.tree.tree-mobile li.sole-root > div.data:after,
    ul.tree.tree-mobile li.is-sole-parent > div.data:after,
    ul.tree.tree-mobile li.is-sibling > div.data:after,
    ul.tree.tree-mobile li:not(:last-of-type).has-next-sibling:after,
    ul.tree.tree-mobile li.force-show:after {
        height: 100%;
        width: 3px;
        left: 8px;
        background-image: url('../../assets/mobile-branch.svg');
        background-size: 100% 30px;
        background-position-y: 0;
    }

    ul.tree.tree-mobile li.sole-root > div.data:after,
    ul.tree.tree-mobile li.is-sole-parent > div.data:after,
    ul.tree.tree-mobile li.is-sibling > div.data:after {
        top: 12px;
        left: -12px;
    }

    ul.tree li > div.see-more,
    ul.tree li li > div.see-more,
    ul.tree li > div.see-less,
    ul.tree li li > div.see-less {
        cursor: pointer;
        color: $bga-blue-light;
    }

    ul.tree.tree-tablet li.has-see-more:before {
        height: 24px;
        width: 24px;
        background-size: 24px;
        background-position-y: -4px;
        background-image: url('../../assets/Tablet-ellipse-open.svg');
    }

    ul.tree.tree-mobile li.has-see-more:before {
        height: 20px;
        width: 20px;
        background-size: 20px;
        background-position-y: -3px;
        background-image: url('../../assets/Mobile-ellipse-open.svg');
    }

    ul.tree li > div.see-more,
    ul.tree li li > div.see-more,
    ul.tree li > div.see-less,
    ul.tree li li > div.see-less {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 20px;
        padding-left: 10px;

        .link {
            cursor: pointer;
            padding: 0;
            text-decoration: none;
            &:focus {
                border-bottom: 2px solid $bga-blue-focus;
            }
            span {
                display: block;
                text-decoration: underline;
                text-underline-offset: 4px;
            }
            font-weight: normal;
            border-bottom: 2px solid transparent;
        }
    }

    ul.tree.tree-tablet li.has-see-less:before {
        height: 24px;
        width: 24px;
        background-size: 24px;
        background-position-y: -14px;
        background-image: url('../../assets/Tablet-ellipse-close.svg');
    }

    ul.tree.tree-tablet li.has-see-more,
    ul.tree.tree-mobile li.has-see-more,
    ul.tree.tree-tablet li.has-see-less,
    ul.tree.tree-mobile li.has-see-less {
        line-height: 24px;
    }

    ul.tree.tree-mobile li.has-see-less:before {
        height: 20px;
        width: 20px;
        background-size: 20px;
        background-position-y: -11px;
        background-image: url('../../assets/Mobile-ellipse-close.svg');
    }
}
