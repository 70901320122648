@import '../../../styles/variables';

/* Steps */
ul.stepped-navigation {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-evenly;
    line-height: 1.78;

    li {
        padding: 40px 8px 0 8px;
        display: block;
        position: relative;
        width: 100%;
    }

    li .step-container,
    li .step-container .step-name {
        text-decoration: none;
        color: $bga-darker-grey;
        text-align: center;
        display: inline-block;
        width: 100%;
        border: 1px dotted transparent;
    }

    li .step-container .step-name {
        opacity: 0.5;
    }

    li .step-container .step-icon {
        height: 32px;
        width: 32px;
        text-decoration: none;
        border: solid 2.5px $bga-silver-polish;
        opacity: 1;
        border-radius: 50%;
        background: $bga-white;
        position: absolute;
        top: 0;
        left: calc(50% - 20px);
        z-index: 10;
    }

    li .step-container .step-icon:hover,
    li .step-container .step-name:hover {
        cursor: default;
    }

    li .step-container .step-icon svg {
        display: none;
    }

    li .step-container .step-icon .step-number {
        font-weight: 600;
        color: $bga-darker-grey;
        opacity: 0.5;
    }

    li .step-container:after {
        content: '';
        position: absolute;
        top: 16px;
        left: calc(50%);
        width: 100%;
        height: 2.5px;
        background: $bga-silver-polish;
        z-index: 0;
    }

    li:last-of-type .step-container:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        background: transparent;
        z-index: 0;
    }

    /* Completed steps */

    li.completed-step .step-container .step-name {
        font-weight: 600;
        color: $bga-blue-light;
        opacity: 1;
    }

    li.completed-step .step-container .step-icon {
        border: solid 2.5px $bga-blue-light;
        border-radius: 50%;
        color: $bga-white;
        background: $bga-blue-light;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
    }

    li.completed-step .step-container .step-icon .step-number {
        display: none;
    }

    li.completed-step .step-container .step-icon:hover,
    li.completed-step .step-container .step-name {
        cursor: default;
    }

    li.completed-step .step-container .step-icon svg {
        display: block;
    }

    li.completed-step .step-container:after {
        background: $bga-blue-light;
    }

    /* Current step */

    li.current-step .step-name,
    li.current-step .step-container .step-number {
        font-weight: 600;
        color: $bga-blue-light;
        opacity: 1;
    }

    li.current-step .step-container .step-icon {
        border: solid 2.5px $bga-blue-light;
        opacity: 1;
        border-radius: 50%;
    }
}

/* Media Query */
@media (max-width: $screen-sm) {
    ul.stepped-navigation {
        li .step-container .step-name {
            display: none;
        }
    }
}
