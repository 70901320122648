@import 'variables';
@import 'headings';
@import 'bodyText';
@import 'tags';
@import 'icons';
@import 'formControls';

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/transitions';
@import '../../node_modules/bootstrap/scss/dropdown';
@import '../../node_modules/bootstrap/scss/navbar';
@import '../../node_modules/bootstrap/scss/card';
@import '../../node_modules/bootstrap/scss/badge';
@import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/helpers';
@import '../../node_modules/bootstrap/scss/utilities/api';

html {
    font-size: $font-size-base;
    height: 100%;
}

body {
    font-family: $font-family-base;
    color: $bga-black;
    line-height: 28px;
    letter-spacing: 0;
}

.container,
.container-fluid {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.container {
    max-width: $container-xxl;
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;

    @media (max-width: $screen-xs-max) {
        padding-left: $grid-gutter-width-threequarters;
        padding-right: $grid-gutter-width-threequarters;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
}

* {
    &:focus-visible {
        outline: thin solid $bga-blue-background-dark;
    }
}

.loader {
    > ._loading_overlay_overlay {
        background-color: $bga-white;

        ._loading_overlay_content {
            color: $bga-black;

            ._loading_overlay_spinner svg circle {
                stroke: red;
            }
        }
    }
}

@include headings();
@include bodyText();
@include tags();
@include icons();
@include formControls();
