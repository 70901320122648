@import '../../../styles/variables';

@mixin filter-radiobutton($primaryColor) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;

    .filter-radiobutton-item {
        display: flex;
        padding-right: 15px;

        .radio {
            display: block;
            position: relative;
            font-weight: normal;
            margin-bottom: 10px;

            label {
                cursor: pointer;
                padding: 0 $grid-gutter-width-onequarter 0 42px;
                display: flex;
            }

            input[type='radio'] {
                color: grey;
                background: url('../../../assets/notselected.svg') no-repeat;
                background-size: 24px 24px;
                content: '';
                position: absolute;
                height: 24px;
                width: 24px;
                left: 8px;
                top: 1px;
            }

            input[type='radio']:checked::before {
                content: url('../../../assets/selected.svg');
            }
        }
    }

    .filter-radiobutton-count {
        margin-right: 6px;
    }
}

.filter-radiobutton {
    @include filter-radiobutton($bga-blue);
}
