@import '../../styles/variables';

.left-hand-content-menu {
    .leftNavHeading {
        font-weight: $font-weight-semibold;
    }

    .left-nav-list {
        padding-left: 0;
        margin-bottom: $grid-gutter-width-threequarters;

        .left-nav-list-item {
            position: relative;
            display: block;
            padding: 0 0 $grid-gutter-width-half;
            list-style-type: none;

            a {
                font-size: 14px;
                line-height: 20px;
                text-decoration: none;
                display: block;
                border: 20px;

                &:hover {
                    font-weight: $font-weight-semibold;
                    text-decoration: underline;
                }

                &.is-current {
                    font-weight: $font-weight-semibold;
                    border-left: 3px solid $bga-blue-links;
                    padding-left: $grid-gutter-width-onequarter;
                    display: inline-block;
                }
            }
        }
    }
}
