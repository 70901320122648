@import '../../styles/variables';
@import '../../styles/reactSelect';

.search-btn {
    position: relative;

    button {
        position: relative;
        min-height: 48px !important;
        min-width: 56px !important;
        background-color: $bga-blue-background-dark;
        border-radius: 0 6px 6px 0;
        border: 0;
        padding: 0;
        height: 48px;
        margin: 0;

        &:hover {
            background-color: $bga-blue-links !important;
        }
        &:focus {
            background-color: $bga-blue-background-light;
            border: 1px solid $bga-blue-background-dark;
        }
    }

    .clear-text-container {
        position: absolute;
        left: -32px;
        height: 48px;
        display: flex;
        align-items: center;
        line-height: 0;

        &:focus-visible {
            outline-width: medium;
        }

        .clear-text {
            height: 16px !important;
            width: 16px !important;
        }
    }
}

.site-search__single-value,
.site-search__indicators {
    @include _react-select-indicators;
}

.site-search__input-container {
    @include _react-select-input-container;
}

.site-search__menu {
    @include _react-select-menu;

    div[class*='__option'] {
        &:first-of-type {
            display: none;
        }
    }

    width: calc(100% + 55px) !important;
}
