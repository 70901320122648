@import '../../styles/variables';

.page-header {
    position: relative;
    width: 100%;
    height: auto;
    background-size: cover;
    background-blend-mode: multiply;
    padding-top: $grid-gutter-width;
    padding-bottom: 0;

    .header-label {
        font-size: $font-size-base * 1.125;
        font-weight: $font-weight-semibold;
        line-height: 27px;
    }

    .header-intro {
        font-size: $font-size-base * 1.125;
        line-height: 32px;
    }

    .last-updated {
        font-size: $font-size-base * 0.75;
        font-style: italic;
        line-height: 18px;
    }

    .event-date {
        font-size: $font-size-base * 0.875;
        line-height: 18px;
    }

    > .container {
        .content-header {
            display: flex;
            align-content: center;
            position: relative;

            .component-content {
                @media (min-width: $screen-lg) {
                    width: 70%;
                }
                @media (min-width: $screen-xl) {
                    width: 66.666666%;
                }
            }

            .component-image {
                margin: 0;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                @media (min-width: $screen-lg) {
                    width: 30%;
                }
                @media (min-width: $screen-xl) {
                    width: 33.333333%;
                }
            }
        }
    }
}

// page header background theme
.page-header-blue-theme {
    background: linear-gradient(0deg, $bga-light-blue-grey, $bga-carolina-blue) 0 0 no-repeat;

    .content-header {
        background-color: $bga-tropical-blue;
    }
}

.page-header-teal-theme {
    background: linear-gradient(0deg, $bga-teal-tool-light, $bga-jungle-mist) 0 0 no-repeat;

    .content-header {
        background-color: $bga-teal-highlight-light;
    }
}

.page-header-yellow-theme {
    background: linear-gradient(0deg, $bga-astra, $bga-jasmine) 0 0 no-repeat;

    .content-header {
        background-color: $bga-yellow-highlight-light;
    }
}

.page-header-purple-theme {
    background: linear-gradient(0deg, $bga-purple-opacity-40, $bga-purple-opacity-30) 0 0 no-repeat;
    color: $bga-blue-black-dark;

    h1 {
        color: $bga-blue-black-dark;
    }

    .content-header {
        background-color: $bga-white;
        border-bottom: 1px solid $bga-blue-black-light;
    }
}

@media (max-width: $screen-md-max) {
    .page-header {
        > .container {
            .content-header {
                .component-content {
                    width: 100%;
                }

                .component-image {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    .page-header {
        .header-label {
            font-size: $font-size-base * 0.875;
            line-height: 21px;
        }
    }
}

@media (max-width: $screen-xs-max) {
    .page-header {
        padding-top: 0;

        .header-intro {
            font-size: $font-size-base;
            line-height: 28px;
        }

        > .container {
            padding: 0;
        }
    }
}
