@import '../../styles/variables';

.sidebar-container {
    margin-bottom: $grid-gutter-width-threequarters;
    padding: $grid-gutter-width-threequarters;

    > * {
        color: $bga-black;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    &.blue {
        background-color: $bga-blue-highlight-light;
    }

    &.yellow {
        background-color: $bga-yellow-highlight-light;
    }

    &.purple {
        background-color: $bga-purple-highlight-light;
    }

    &.teal {
        background-color: $bga-teal-highlight-light;
    }

    &.green {
        background-color: $bga-green-highlight-light;
    }

    &.orange {
        background-color: $bga-orange-highlight-light;
    }

    > .text-container {
        &.fixed-height {
            height: 220px;
            overflow: hidden;
            position: relative;

            &.with-icon {
                height: 280px;
            }

            &::after {
                content: '';
                width: 100%;
                height: 32px;
                bottom: 0;
                position: absolute;
            }

            $gradientColor: rgba(207, 235, 235, 0) 100%;

            &.blue::after {
                background: linear-gradient(0deg, $bga-blue-highlight-light 30%);
            }

            &.yellow::after {
                background: linear-gradient(0deg, $bga-yellow-highlight-light 30%, $gradientColor);
            }

            &.green::after {
                background: linear-gradient(0deg, $bga-green-highlight-light 30%, $gradientColor);
            }

            &.teal::after {
                background: linear-gradient(0deg, $bga-teal-highlight-light 30%, $gradientColor);
            }

            &.orange::after {
                background: linear-gradient(0deg, $bga-orange-highlight-light 30%, $gradientColor);
            }

            &.purple::after {
                background: linear-gradient(0deg, $bga-purple-highlight-light 30%, $gradientColor);
            }
        }

        > .icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: $grid-gutter-width-half;

            > img {
                height: 32px;
                width: 32px;

                box-sizing: content-box;
                border: 5px solid $bga-white;
                border-radius: 50%;
                background-color: $bga-white;
            }
        }
    }

    button {
        text-decoration: none;
    }
}
