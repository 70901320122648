@import '../../styles/variables';

@mixin _iconButton($icon, $leftOrRight) {
    $borderTopRadius: 'border-top-#{$leftOrRight}-radius';
    $borderBottomRadius: 'border-bottom-#{$leftOrRight}-radius';

    display: flex;
    justify-content: flex-end;
    margin-bottom: 1px;

    button {
        text-indent: -9999px;
        min-height: 48px;
        min-width: 56px;
        border: none;
        #{$borderTopRadius}: 6px;
        #{$borderBottomRadius}: 6px;
        background: url(../../assets/icon-#{$icon}.svg) $bga-blue-background-dark no-repeat;
        background-position: center center;
        background-size: 24px;
    }

    &.open {
        button {
            background: url(../../assets/icon-close.svg) $bga-blue-background-light no-repeat;
            background-position: center center;
            border: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &:not(:last-of-type) {
        margin-right: 4px;
    }
}

.header-title {
    padding-top: $grid-gutter-width;

    .au-gov-logo {
        max-width: 120px;
    }

    .au-gov-logo-divider {
        border-right: 1px solid $bga-blue-black-dark;
        height: 55px;
        margin: $grid-gutter-width-onequarter 10px 10px;
    }

    .au-gov-logo-text {
        .logo-text {
            margin-bottom: 0.2rem;
            color: $bga-blue-black-dark;
            font-weight: bold;
            font-size: $h3-font-size;
            text-wrap: nowrap;
        }

        .logo-sub-text {
            color: $bga-blue-black-dark;
            font-size: $font-size-base * 0.75;
            font-weight: normal;
            text-wrap: nowrap;
        }
    }

    .header-logo-holder {
        flex: 0 0 auto;
        width: 50%;

        @media (max-width: $screen-below-xs) {
            width: 100%;
        }
    }

    .menu-search {
        justify-content: flex-end;

        @media (max-width: $screen-below-xs) {
            justify-content: flex-start;
        }
    }

    .mobile-nav-menu {
        @include _iconButton('menu', 'right');
    }

    .mobile-search {
        @include _iconButton('search', 'left');
    }

    .search-field {
        justify-content: end;
        align-self: center;

        > div {
            position: relative;
            @media screen and (max-width: $screen-sm-max) {
                background-color: $bga-blue-background-light;
                padding: $grid-gutter-width-threequarters;
                width: 100% !important;
            }
        }
    }
}

@media (min-width: $screen-md) {
    .header-title {
        .logo-text {
            margin-top: 0.4rem;
            margin-bottom: 0.22rem;
        }
    }
}

@media (min-width: $screen-sm) {
    .header-title {
        .logo-text {
            padding-top: 6px;
        }
    }
}

@media (max-width: $screen-sm-max) {
    .header-title {
        padding-top: $grid-gutter-width-threequarters;

        .au-gov-logo-text {
            padding-top: 5px;
            display: flex;
            height: 48px;
            align-items: center;

            .logo-text {
                font-size: 20px;
                color: $bga-blue-links;
            }
        }
    }
}
