/*NOTE: Variables in this file come from BGA style guide (https://ausgov.github.io/bga-style-guide)
We should only copy these from BGA style guide, not modify value. Use _variables.scss to override it.
The only alteration we should make to this file is to comment out the bootstrap import.*/

/* BGA VARIABLES ---------------------------------------- */

// BGA Colours
//
// BGA Blues
$bga-blue-links: #2157aa;
$bga-blue-background-dark: #2e6cb9;
$bga-blue-background-light: #c8d9ee;
$bga-blue-background-extralight: #e3ecf6;
$bga-blue-border: #96b7df;
$bga-blue-footer: #113869;
$bga-blue-hover: rgba(46, 108, 185, 0.15);

// Highlight colours
$bga-blue-highlight-shadow: #708daf;
$bga-blue-highlight-dark: #96b7df;
$bga-blue-highlight-mid: #b3cbe8;
$bga-blue-highlight-light: #c8d9ee;

$bga-teal-highlight-shadow: #489595;
$bga-teal-highlight-dark: #5ab3b3;
$bga-teal-highlight-mid: #8bcaca;
$bga-teal-highlight-light: #cfebeb;

$bga-green-highlight-shadow: #629d62;
$bga-green-highlight-dark: #85cb85;
$bga-green-highlight-mid: #a0d1a0;
$bga-green-highlight-light: #d4edd4;

$bga-yellow-highlight-shadow: #edc95a;
$bga-yellow-highlight-dark: #ffd964;
$bga-yellow-highlight-mid: #ffe491;
$bga-yellow-highlight-light: #fff3cd;

$bga-orange-highlight-shadow: #cc7a29;
$bga-orange-highlight-dark: #e88a2d;
$bga-orange-highlight-mid: #f1b981;
$bga-orange-highlight-light: #fde6d0;

$bga-purple-highlight-shadow: #7468b4;
$bga-purple-highlight-dark: #9183d8;
$bga-purple-highlight-mid: #b1a8e3;
$bga-purple-highlight-light: #e2ddf8;

// Utility colours
$bga-black: #333333;
$bga-white: #ffffff;
$bga-purple-links-visited: #933aa8;
$bga-yellow-links-background: #ffcf3b;

$bga-blue-black-dark: #2e3e5a;
$bga-blue-black-mid-dark: #5a667d;
$bga-blue-black-mid: #788295;
$bga-blue-black-light: #abb1bd;
$bga-blue-black-lightest: #e4e6ea;
$bga-blue-black-background-lightest: #eff0f2;

$bga-blue-focus: #2e6cb9;
$bga-teal-tool-dark: #105c5c;
$bga-teal-tool-mid: #218080;
$bga-teal-tool-light: #bfe2e2;
$bga-teal-tool-extra-light: #e6f3f3;

$bga-red-fail-dark: #c9201d;
$bga-red-fail-mid: #f5b5b5;
$bga-red-fail-light: #fce9e9;

$bga-green-success-background: #068905;
$bga-green-success-dark: #299f28;
$bga-green-success-mid: #a0d1a0;
$bga-green-success-light: #d4edd4;

$bga-skin-1: #b28b67;
$bga-skin-2: #efc6a1;

//Box shadows and borders
$bga-btn-boxshadow: 2px 2px 3px 0px rgba(51, 51, 51, 0.2);
$bga-component-border: 1px solid $bga-blue-border;
$bga-text-focus-border: 2px solid $bga-blue-focus;
$bga-component-focus-border: 2px solid $bga-blue-focus;
$bga-focus-boxshadow: $bga-blue-focus 0px 0px 0px 2px;

$bga-component-error-border: 3px solid $bga-red-fail-dark;
$bga-error-boxshadow: $bga-red-fail-dark 0px 0px 0px 3px;

$bga-focus-radius: 4px;

// NON branded base colours
$nb-grey-lightest: #f8f8f8;
$nb-grey-light: #e8e8e8;
$nb-grey-mid: #d8d8d8;
$nb-grey-dark: #c8c8c8;
$nb-grey-darkest: #b8b8b8;
$nb-grey-extra-darkest: #a8a8a8;
$nb-black-light: #757373;

$nb-blue-links: mediumblue;

/* OVERRIDE BOOTSTRAP VARIABLES ---------------------------------------- */

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    //4px
    2: $spacer * 0.5,
    //8px
    3: $spacer,
    //16px
    4: $spacer * 1.5,
    //24px
    5: $spacer * 2,
    //32px
    6: $spacer * 3,
    //48px
    7: $spacer * 3.5,
    //56px
    8: $spacer * 4,
    //64px
    9: $spacer * 5,
    //80px
);

// Options
//
// Disable responsive font sizing
$enable-rfs: false;

// Container padding
$container-padding-x: 32px;

// Body
//
// Settings for the `<body>` element.
$body-color: $bga-black;
$body-text-align: left;

// Links
//
// Style anchor elements.
$link-color: $bga-blue-links;

// Paragraphs
//
// Style p element.
$paragraph-margin-bottom: 24px;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-gutter-width: 32px;
$gutters: $spacers !default;

// Container padding
$container-padding-x: $grid-gutter-width * 0.5 !default;

// Cards
$card-height: 100%;
$card-border-width: 1px;
$card-border-color: $bga-blue-border;
$card-border-radius: 0px;
$card-spacer-y: $spacer * 1.5;
$card-spacer-x: $spacer * 1.5;

// scss-docs-start border-variables
$border-widths: (
    1: 1px,
    2: 2px,
    3: 3px,
    4: 4px,
    5: 5px,
    8: 8px,
    16: 16px,
);

$box-shadow: 0.2rem 0.2rem 0.3rem 0 rgba($bga-black, 0.2);

// Typography
//
// scss-docs-start font-variables
$font-family-sans-serif: 'Open Sans', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: 16px;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base;
$font-size-lg: $font-size-base;

$font-weight-semibold: 600;

$line-height-sm: 1.5;
$line-height-lg: 1.5;

$h1-font-size: $font-size-base * 2.75;
$h2-font-size: $font-size-base * 2.125;
$h3-font-size: $font-size-base * 1.375;
$h4-font-size: $font-size-base * 1.1875;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;
// scss-docs-end font-variables

// scss-docs-start headings-variables
$headings-margin-bottom: 24px;
$headings-font-weight: 600;
$headings-line-height: 1.5;
$headings-color: $bga-blue-black-dark;
// scss-docs-end headings-variables

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.75rem !default;
$input-btn-padding-x: 32px;
$input-btn-border-width: 3px;
$input-btn-focus-width: 2px;
$input-btn-focus-color-opacity: 1;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-font-weight: $font-weight-semibold;
$btn-focus-width: $input-btn-focus-width !default;
$btn-link-color: $bga-black;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 50px;

// Navs

// scss-docs-start nav-variables
$nav-link-padding-y: 0.375rem;

///* IMPORT BOOTSTRAP SASS ------------------------------------------ */
//
//@import "bootstrap";

/* BGA MIXINS ------------------------------------------ */

@mixin hero-color($border, $arrow, $external-link) {
    .component-content {
        border-left: 16px $border solid;
    }

    .arrow-icon {
        background: $arrow;
    }
    .icon-wrapper .external-site {
        background: $external-link;
    }
}
// @include hero-color($bga-purple-highlight-dark, $bga-purple-highlight-mid, $bga-purple-highlight-light);

@mixin responsive-img() {
    width: 100%;
    max-width: 100%;
    height: auto;
}
// @include responsive-img();

@mixin colour-example($bga-colour) {
    .colour {
        background: $bga-colour;
    }
}
