@import '../../styles/variables';

.results-container {
    hr {
        border: 0;
        width: 3.25rem;
        margin: 0;
        text-align: left;
        border-top: 0.125rem solid $bga-yellow;
        padding-bottom: $grid-gutter-width-threequarters;
    }
}
