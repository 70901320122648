@import '../../styles/variables';

.image-container {
    max-width: 100%;
    width: 100%;
    padding-bottom: $grid-gutter-width;

    .responsive-image {
        width: 100%;
        display: block;
    }

    .caption {
        background: $bga-grey-lighter;
        padding: $grid-gutter-width-half;
        max-width: 100%;
        width: 100%;
        display: block;
    }

    p {
        padding: 0;
    }
}
