@import '../../styles/variables';

.accept-cookies-banner {
    background-color: $bga-white;

    a:link,
    a:visited,
    a:hover,
    a:active {
        color: $bga-blue-light;
        text-decoration: underline;
    }
}

.feedback-banner {
    background-color: $bga-blue-footer;
    color: $bga-white;

    a {
        text-decoration: none;

        &:link,
        &:visited,
        &:hover,
        &:active {
            color: $bga-white;
        }

        &:hover {
            font-weight: 400;
            text-decoration: underline;
        }

        &:focus-visible {
            border: 2px solid $bga-white;
            border-radius: $bga-focus-radius;
            box-shadow: none;
            outline: none;
        }
    }

    .businessgovau-holder {
        display: inline-block;
        align-self: center;
        margin: ($grid-gutter-width-half * 0.75) 0;

        img {
            padding-bottom: 3px;
            padding-right: $grid-gutter-width-onequarter;
        }
    }

    .feedback-holder {
        display: inline-block;
    }

    .feedback-link {
        list-style: none;
        margin: 12px 0;

        a {
            text-decoration: underline;
        }
    }

    .account-menu-container {
        display: flex;
        justify-content: flex-end;
    }
}
