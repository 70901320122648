@import '../../styles/variables';

.uncommtrade-container {
    width: 100%;

    .ef-prof-link {
        padding-top: $grid-gutter-width-half;
    }

    .uncommtrade-content {
        max-width: 800px;
        margin-top: $grid-gutter-width;
        margin-bottom: $grid-gutter-width;

        .uncommtrade {
            .text {
                p {
                    font-size: 21px;
                    font-weight: 600;
                }
            }

            img {
                width: 78px;
                height: 75px;
                right: auto;
                top: 0;
                bottom: 0;
                border-width: 0;
            }
        }
    }
}
