@import '../../styles/variables';

.accordion {
    .accordion-item {
        &:hover h4 {
            box-shadow: inset 0 -2.08px 0 $bga-darker-grey;
        }

        .accordion-item-tile {
            cursor: pointer;
            -webkit-box-sizing: border-box;
            -webkit-transition: background-color 0.5s;
            border-bottom: 0.0625rem solid $bga-grey-lighter;
            box-sizing: border-box;
            position: relative;
            transition: background-color 0.5s;

            .accordion-item-tile-inner {
                -ms-flex-align: center;
                -ms-flex-pack: justify;
                -webkit-box-align: center;
                -webkit-box-pack: justify;
                align-items: center;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                justify-content: space-between;
                padding: 2rem;

                .accordion-item-tile-inner-title {
                    max-width: 85%;
                    overflow: hidden;

                    + button.arrow {
                        min-width: 48px;
                        min-height: 48px;
                        padding: 0;
                    }

                    h4 {
                        display: inline;
                        font-weight: bolder;
                    }

                    h4.closed {
                        color: $bga-darker-grey;
                    }

                    h4.expanded {
                        color: $bga-black;
                    }
                }

                .arrow {
                    margin-left: 2rem;
                    border: none !important;
                    display: flex;
                    align-items: center;

                    .float-button-content {
                        margin: 0 auto;
                        display: inline;
                        justify-content: center;

                        img {
                            width: 1rem;
                        }
                    }

                    &.closed {
                        color: $bga-black;
                        width: 48px;
                        height: 48px;
                        background-color: #f5c550;
                        border-radius: 50px;
                        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
                    }

                    &.closed:hover {
                        background-color: $bga-yellow-highlight-dark;
                        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
                    }

                    &.closed:active {
                        background-color: $bga-yellow-tint;
                        box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.3);
                    }

                    &.expanded {
                        color: $bga-black;
                        width: 48px;
                        height: 48px;
                        background-color: #f5c550;
                        border-radius: 50px;
                        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
                    }

                    &.expanded:hover {
                        background-color: $bga-yellow-highlight-dark;
                        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
                    }

                    &.expanded:active {
                        background-color: $bga-yellow-tint;
                        box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.3);
                    }
                }
            }

            &:focus-visible {
                border-radius: 3px;
                box-shadow: 0 0 0 2px $bga-mariner;
                outline: none;
            }
        }

        .accordion-item-tile:hover {
            border-bottom: solid 4px $bga-yellow-highlight-dark !important;

            h4 {
                color: $bga-black;
                box-shadow: inset 0 -2.08px 0 $bga-darker-grey;
            }
        }

        .accordion-item-tile.open {
            border-bottom: 0.0625rem solid $bga-grey-lighter !important;
            background-color: $bga-grey-lighter !important;
        }

        .accordion-item-content {
            display: none;
            transition-property:
                transform,
                -webkit-transform;
            transition-duration: 0.4s, 0.4s;
            transition-timing-function: ease, ease;
            transition-delay: 0s, 0s;
            max-height: 0;
        }

        .accordion-item-content.visible {
            display: block;
            background-color: $bga-grey-lighter !important;
            padding: 0 112px 0 $grid-gutter-width;
            border-bottom: solid 4px $bga-yellow-highlight-dark;
            max-height: none;
        }
    }
}
