@import '../../styles/variables';
$personalised-filter-border-bottom: $bga-yellow-blue-yonder;

.filtergroup-form-field-container {
    width: 100%;
    margin-bottom: $grid-gutter-width;
}

.filtergroup-form-field-container .form-field {
    border: solid 1px $bga-black;
    width: 100%;
    padding: 12px 15px;
    box-sizing: border-box;
}

.filtergroup-clear-filters {
    background: none;
    box-shadow: initial;
    display: inline-block;
    flex-grow: 0;

    p {
        color: $bga-blue-light;
        text-decoration: none;
        box-shadow: none;
        border-bottom: solid 0.2rem $personalised-filter-border-bottom;
        margin-top: 0.5rem;
        padding-right: 1.5rem;
        display: inline-block;
        position: relative;
        font-weight: bold;

        span {
            height: 1rem;
            width: 1rem;
            display: inline-block;
            background: url(../../assets/refresh.svg) no-repeat;
            margin-left: 0.5rem;
            position: absolute;
            top: 0.3rem;
            right: 0;
        }
    }

    &:hover,
    &:active {
        background-color: initial;
        box-shadow: none;
    }
}

.apply-filter-container {
    flex-grow: 1;
    text-align: right;
}

.filtergroup-filter-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 2em;
}

.sticktoright {
    margin: 0 !important;
}
