@import '../../../styles/variables';

.image-pathway-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    width: auto !important;

    .image-pathway-list-block {
        display: flex;
        flex-direction: column;
        padding-bottom: $grid-gutter-width;

        .image-pathway-anchor-wrapper {
            height: 100% !important;

            a.image-pathway-anchor {
                border: 1px solid $bga-blue-border;
                background: $bga-white;
                display: block;
                height: 100%;
                text-decoration: none !important;

                .image-pathway-list-button {
                    border: none;
                    cursor: pointer;
                    flex: 1;
                    height: 100%;
                    padding: 0;
                    text-align: left;
                    width: 100%;
                }

                .image-pathway-list-wrapper {
                    padding: 0;
                    position: relative;

                    .image-pathway-list-flexItem {
                        height: 240px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .image-pathway-list-body {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        justify-content: space-between;
                        padding: $grid-gutter-width-threequarters $grid-gutter-width-threequarters ($grid-gutter-width * 1.5);

                        .image-pathway-list-content {
                            padding-top: 0;

                            h3 {
                                color: $bga-blue-links;
                            }
                        }

                        .image-pathway-list-description {
                            color: $bga-black;
                            font-size: 1rem;
                            padding-bottom: 0;
                        }

                        .image-pathway-list-footer {
                            position: absolute;
                            align-items: center;
                            display: flex;
                            margin-bottom: ($grid-gutter-width * 0.375);
                            margin-top: auto;
                            bottom: 10px;

                            .image-pathway-list-icon {
                                width: 32px;
                                height: 32px;
                                background: $bga-blue-background-dark;
                                border-radius: 50px;
                            }

                            .arrow-right-white-icon {
                                position: absolute;
                                top: 8px;
                                left: 8px;
                            }
                        }
                    }
                }

                &:hover,
                &:focus {
                    box-shadow: none;
                    background-color: $bga-blue-background-extralight;
                    font-weight: normal !important;

                    .image-pathway-list-body {
                        .image-pathway-list-content {
                            text-decoration: underline;
                            text-decoration-color: $bga-blue;
                        }

                        .image-pathway-list-description {
                            text-decoration: none !important;
                        }
                    }
                }

                &:focus {
                    border: 2px solid $bga-blue-focus;
                }
            }
        }
    }
}
