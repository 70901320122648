@import '../../styles/variables';

.body-copy {
    > div {
        > h2 {
            padding-top: $grid-gutter-width-threequarters;
        }

        > h3 {
            padding-top: $grid-gutter-width-half;
        }

        > h4,
        > h5 {
            padding-top: $grid-gutter-width-onequarter;
        }
    }
}
