@import 'variables';

@mixin formControls() {
    .focus-outline {
        outline: none;
        border-radius: 4px;
        border: 1px solid $bga-blue-background-dark;
        box-shadow: 0 0 0 1px $bga-blue-background-dark;
    }

    label {
        &.radio-button.selected {
            &.focused,
            &.activated {
                @extend .focus-outline;
            }
        }
    }

    input,
    select {
        background: $bga-white;
        border: $bga-blue-black-dark solid 1px;
        border-radius: 0;
        font-size: 1rem;
        line-height: 1.75;
        padding: $grid-gutter-width-onequarter * 1.5 $grid-gutter-width-half;
        width: 100%;

        &:not[class*='__input'] {
            min-height: 56px;
        }

        &[class*='__input'] {
            box-shadow: none !important;
        }

        &:not([type='radio'], [type='checkbox']):focus {
            @extend .focus-outline;
        }
    }

    select {
        appearance: none !important;
        background: $bga-white url(../../src/assets/chevronDown333.svg) no-repeat;
        background-size: 16px 10px;
        background-position: right 20px center;
        color: $bga-black;
        padding-right: $grid-gutter-width * 1.5;
        text-overflow: ellipsis;
        white-space: nowrap;

        > option {
            color: $bga-black;

            &.selected {
                color: $bga-black;
            }
        }
    }
}
