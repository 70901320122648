@import '../../styles/variables';
@import '../../styles/link';

.button-container {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;

    button:not(:last-of-type) {
        margin-bottom: 0;
        margin-right: $grid-gutter-width-threequarters;
    }

    @media (max-width: $screen-below-xs) {
        flex-direction: column;

        button:not(:last-child) {
            margin-right: 0;
            margin-bottom: $grid-gutter-width-threequarters;
        }
    }
}

button {
    font-weight: $font-weight-semibold;
    font-size: $font-size-base * 0.875 !important;
    color: $bga-black;

    > svg {
        &:first-child {
            margin-right: $grid-gutter-width-onequarter;
        }
        &:last-child {
            margin-left: $grid-gutter-width-onequarter;
        }
    }

    &:disabled {
        opacity: 0.65;
        cursor: default;
    }

    &.primary {
        box-shadow: 0px 2px 2px #00000033;
        background: $bga-yellow-highlight-dark 0% 0% no-repeat;
        border: 3px solid $bga-yellow-highlight-shadow;
        border-radius: 100px;
        opacity: 1;
        line-height: 18px;

        &:focus {
            background: $bga-yellow-links-background 0% 0% no-repeat;
            border: 3px solid $bga-blue-background-dark;
        }

        &:not(:disabled):hover {
            background: $bga-yellow-links-background 0% 0% no-repeat;
            border: 3px solid $bga-yellow-links-background;
        }

        &:not(:disabled):active {
            background: $bga-yellow-highlight-shadow 0% 0% no-repeat;
            border: 3px solid $bga-yellow-highlight-shadow;
        }

        &:disabled {
            opacity: 0.65;
            cursor: default;
        }

        @media (min-width: $screen-sm) {
            line-height: 24px;
        }
    }

    &.secondary {
        border: 3px solid $bga-blue;
        border-radius: 100px;
        background: $bga-white 0 0% no-repeat;
        box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
        opacity: 1;
        line-height: 18px;

        &:focus {
            background: $bga-blue-links 0% 0% no-repeat;
            border: 3px solid $bga-blue-links;
            box-shadow: 0px 2px 2px #00000029;
            color: $bga-white;
        }

        &:not(:disabled):hover {
            background: $bga-blue-background-dark 0% 0% no-repeat;
            border: 3px solid $bga-blue-background-dark;
            box-shadow: 0px 2px 2px #00000029;
            color: $bga-white;
        }

        &:not(:disabled):active {
            background: $bga-blue-light 0 0% no-repeat;
            border: 3px solid $bga-blue;
            transform: translateY(-0.1875rem);
            box-shadow: rgba(0, 0, 0, 0.3) 0 4px 8px 0;
            color: $bga-white;
        }

        @media (min-width: $screen-sm) {
            line-height: 24px;
        }
    }

    &.link {
        @include _link;
    }

    @media (max-width: $screen-below-xs) {
        width: 100%;
        min-height: 48px;
    }
    @media (min-width: $screen-xs) {
        min-width: 80px;
        min-height: 48px;
        padding: $grid-gutter-width-onequarter $grid-gutter-width-threequarters;
    }
    @media (min-width: $screen-sm) {
        min-width: 120px;
        min-height: 56px;
        padding: $grid-gutter-width-onequarter $grid-gutter-width;
        font-size: $font-size-base !important;
    }
}
