@import '../../styles/variables';

.event-tile-pathway {
    height: 100% !important;

    a.event-tile-pathway-anchor {
        border: 1px solid $bga-blue-border;
        background: $bga-white;
        display: block;
        height: 100%;
        text-decoration: none !important;

        .event-tile-pathway-list-button {
            border: none;
            cursor: pointer;
            flex: 1;
            height: 100%;
            padding: 0;
            text-align: left;
            width: 100%;
        }

        .event-tile-pathway-list-wrapper {
            padding: 0;
            position: relative;

            .event-tile-pathway-list-flexItem {
                height: 240px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .event-tile-pathway-list-body {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                justify-content: space-between;
                padding: $grid-gutter-width-threequarters $grid-gutter-width-threequarters ($grid-gutter-width * 1.5);

                .event-tile-pathway-list-label-wrapper {
                    display: flex;

                    .event-tile-pathway-list-label-text {
                        color: $bga-black;
                        margin-bottom: $grid-gutter-width-threequarters;
                    }
                }

                .event-tile-pathway-list-title {
                    padding-top: 0;

                    h3 {
                        color: $bga-blue-links;
                        text-decoration: underline;
                    }
                }

                .event-tile-pathway-list-date-wrapper {
                    display: flex;
                    margin-bottom: $grid-gutter-width-threequarters;
                    .text {
                        font-weight: 600 !important;
                        color: $bga-blue-black-dark;
                    }
                }

                .event-tile-pathway-list-description {
                    color: $bga-black;
                    font-size: 1rem;
                    padding-bottom: 0;
                }

                .event-tile-pathway-list-footer {
                    position: absolute;
                    align-items: center;
                    display: flex;
                    margin-bottom: ($grid-gutter-width * 0.375);
                    margin-top: auto;
                    bottom: 10px;

                    .event-tile-pathway-list-icon {
                        width: 32px;
                        height: 32px;
                        background: $bga-blue-background-dark;
                        border-radius: 50px;
                    }

                    .arrow-right-white-icon {
                        position: absolute;
                        top: 8px;
                        left: 8px;
                    }
                }
            }
        }

        &:hover,
        &:focus {
            box-shadow: none !important;
            background-color: $bga-blue-background-extralight;
            font-weight: normal !important;

            .event-tile-pathway-list-body {
                .event-tile-pathway-list-content {
                    text-decoration: underline;
                    text-decoration-color: $bga-blue;
                }

                .event-tile-pathway-list-description {
                    text-decoration: none !important;
                }
                .tag {
                    background: $bga-white !important;
                }
            }
        }

        &:focus {
            border: 2px solid $bga-blue-focus;
        }
    }
}
