@import '../../../styles/variables';

.filter-checkbox-panel {
    margin-bottom: $grid-gutter-width-onequarter;

    .filter-checkbox-selection {
        margin-bottom: $grid-gutter-width-onequarter;
    }

    > .filter-checkbox-expanded {
        display: block;
        > * {
            margin-top: $grid-gutter-width-onequarter * 0.5;
        }
    }

    > .filter-checkbox-collapsed {
        display: none;
    }
}
