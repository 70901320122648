@import '../../styles/variables';

.event-details {
    .event-details-content-wrapper {
        max-width: 100%;
        border-left: solid 8px $bga-blue-border;

        .event-details-content {
            background: $bga-blue-background-extralight;

            .center-icon {
                display: flex;
                justify-content: center;

                .icon-wrapper {
                    width: 42px;
                    height: 42px;
                    background: $bga-white;
                    border-radius: 50%;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    flex-shrink: 0;
                    align-items: center;
                }
            }

            .item {
                display: flex;
                margin-bottom: $grid-gutter-width-threequarters;

                @media (max-width: $screen-sm-max) {
                    display: block;
                }

                .label {
                    font-weight: $font-weight-semibold;
                    min-width: 120px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .event-details-footer {
            background: $bga-blue-background-light;
            display: block;

            @media (max-width: $screen-xs-max) {
                .spots-left {
                    font-size: $font-size-base * 0.875;
                }
            }
        }

        &.booking-confirmed,
        &.join-now {
            border-left: solid 8px $bga-green-success-mid;

            .event-details-content {
                background: $bga-green-success-light;
            }

            .event-details-footer {
                background: rgba($bga-green-success-mid, 0.8);
            }
        }

        &.booking-closed,
        &.event-completed {
            border-left: solid 8px $bga-blue-black-light;

            .event-details-content {
                background: $bga-blue-black-background-lightest;
            }

            .event-details-footer {
                background: $bga-blue-black-lightest;
                height: 104px;
            }
        }
    }
}
