@import '../../styles/variables';
@import '../../styles/modalDialog';

.quiz-results-container {
    ul {
        li {
            p {
                display: inline;
            }
        }
    }
}

.side-bar-container {
    background: $bga-grey-lighter;
    border-top: 4px solid $nb-grey-mid;
}

.modal-wrapper {
    .modal.quiz-results-modal {
        .success {
            background: url(../../assets/greenTickIcon.svg) no-repeat;
            background-position: left center;
            padding-left: 44px;
            height: 32px;
        }

        .progress-link-wrapper {
            margin-bottom: $grid-gutter-width;
            width: 100%;
            position: relative;
            display: inline-block;

            input {
                max-width: none;
                text-decoration: underline;
                background: $bga-grey-lighter;
                border: none;
                outline: none;
                display: inline-block;
                vertical-align: middle;
                height: 52px;
                width: 100%;
                padding: 10px $grid-gutter-width-half;
            }

            .input-fade-effect {
                height: 50px;
                width: 64px;
                position: absolute;
                display: block;
                top: 1px;
                right: 1px;
                background: linear-gradient(90deg, rgba(235, 235, 235, 0) 0%, $bga-grey-lighter 100%);
            }
        }

        .copy-link-button,
        .copied-link-button {
            height: 52px;
            line-height: 1.9rem;
            text-align: left;
            padding: 10px $grid-gutter-width-half 10px ($grid-gutter-width * 1.5);
            width: 180px;
            background-size: 24px;
            background-position: center left 16px;
            background-repeat: no-repeat;
            cursor: pointer;
            font-weight: $font-weight-semibold;
        }

        .copy-link-button {
            background-image: url(../../assets/copy.svg);
        }

        .copied-link-button {
            background-image: url(../../assets/copied.svg);
        }
    }
}
