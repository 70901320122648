@import '../../../styles/variables';

.pathway-list {
    &:has(.inline-pathway) {
        padding-bottom: $grid-gutter-width;
    }

    a[href*="//"]
    {
        &:after {
            content: none;
        }
    }
}
