@import '../../styles/variables';

.protip-wrapper {
    .protip.feature-box {
        max-width: 100%;

        .protip-content {
            display: flex;
            background: $bga-purple-highlight-light;
            border-left: solid 8px $bga-purple-highlight-dark;
            padding-right: $grid-gutter-width-double !important;

            .icon-wrapper {
                width: 56px;
                height: 56px;
                background: $bga-white;
                border-radius: 100px;
                display: flex;
                align-content: center;
                justify-content: center;
                flex-shrink: 0;
                align-items: center;
                margin-right: $grid-gutter-width-half !important;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    .protip-wrapper {
        .protip.feature-box {
            .protip-content {
                flex-wrap: wrap;
                justify-content: center;
                padding-right: $grid-gutter-width-threequarters !important;

                .icon-wrapper {
                    width: 44px;
                    height: 44px;
                    margin-right: 0 !important;
                }
            }
        }
    }
}
