@import '../../styles/variables';

.utility-group {
    align-items: center;
    padding: 0 0 $grid-gutter-width;
    width: 100%;

    @media (min-width: $screen-md) {
        justify-content: flex-end !important;
    }

    .utility-group-heading {
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: $bga-black;
    }
}
