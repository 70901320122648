@import '../../../styles/variables';

.image-tile-pathway-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    width: auto !important;

    .image-tile-pathway-list-block {
        display: flex;
        flex-direction: column;
        padding-bottom: $grid-gutter-width;

        .image-tile-pathway-anchor-wrapper {
            height: 100% !important;

            a.image-tile-pathway-anchor {
                border: 1px solid $bga-blue-border;
                background: $bga-white;
                display: block;
                height: 100%;
                text-decoration: none !important;

                .image-tile-pathway-list-button {
                    border: none;
                    cursor: pointer;
                    flex: 1;
                    height: 100%;
                    padding: 0;
                    text-align: left;
                    width: 100%;
                }

                .image-tile-pathway-list-heading {
                    padding: $grid-gutter-width-threequarters $grid-gutter-width-threequarters 0 $grid-gutter-width-threequarters;
                    h3 {
                        color: $bga-blue-links;
                    }
                }

                .image-tile-pathway-list-wrapper {
                    padding: 0;
                    position: relative;

                    .image-tile-pathway-list-body {
                        height: 240px;

                        .image-tile-pathway-list-body-content {
                            position: absolute;
                            bottom: 0;
                            height: 240px;
                            width: 100%;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }

                &:hover,
                &:focus {
                    box-shadow: none;
                    background-color: $bga-blue-background-extralight;
                    font-weight: normal !important;

                    .image-tile-pathway-list-heading {
                        text-decoration: underline;
                        text-decoration-color: $bga-blue;
                    }
                }

                &:focus {
                    border: 2px solid $bga-blue-focus;
                }
            }
        }
    }
}
