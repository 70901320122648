@import '../../styles/variables';

.mainnavigation {
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width-threequarters;

    .navbar {
        display: block;
        position: relative;
        padding: 0 $grid-gutter-width-half;
        font-weight: $font-weight-semibold;

        .nav-item {
            &::before {
                content: none;
            }

            .nav-link {
                font-weight: $font-weight-semibold;
                border-radius: 4px;

                &:focus {
                    background: $bga-blue-background-light;
                }

                &:hover {
                    background: $bga-blue-background-light;
                }
            }
        }
    }

    .nav-wrapper {
        margin: 0 ($grid-gutter-width * -0.5);
    }

    .nav {
        margin: 0;
        padding: 0;
        display: flex;

        .submenu-wrapper {
            display: inline-block;
        }

        /* LEVEL 1 */
        li {
            display: list-item;
            list-style: none;
            height: 100%;
            padding: 6px $grid-gutter-width-onequarter;
            background: $bga-white;
            color: $bga-blue-links;
            font-weight: $font-weight-semibold;
            margin: 0;
            text-decoration: none;
            cursor: pointer;
            margin-right: $grid-gutter-width-onequarter;
            line-height: 20px;

            &:hover {
                background-color: $bga-blue-background-light;
                border: none;
            }

            &:focus {
                background-color: $bga-blue-background-light;
                border: 2px solid $bga-blue-focus;
            }

            &:active {
                border: none;
            }

            a {
                display: inline-block;
                color: $bga-blue-links;
                font-weight: $font-weight-semibold;
                margin: 0;
                padding: 0 4px 0 0;
                text-decoration: none;
                width: 100%;

                &:focus {
                    background: none;
                    text-decoration: underline;
                }
            }

            &.nav-level-1 {
                border-radius: 4px !important;
            }

            &.nav-level-1.nav-dropdown {
                position: relative;

                &.open {
                    background-color: $bga-blue-background-light;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    border: none;
                }
            }

            .nav-level-1-icon {
                display: inline;
                white-space: nowrap;
            }
        }

        /* LEVEL 2 * 3 */
        .submenu {
            display: none;
            position: absolute;
            background: $bga-blue-background-extralight;
            box-shadow: 2px 2px 3px 0 rgba(51, 51, 51, 0.4);
            border-top: 4px solid $bga-blue-background-light;
            width: 100%;
            left: 0;
            z-index: 8000;
            padding: $grid-gutter-width-threequarters;
            margin: 0;
            columns: 4 250px;
            column-gap: 24px;
            column-fill: balance;
            margin-top: -4px;

            //Begin:fix for safari z-index issue - text overalapping with leftNav header
            overflow-y: auto;
            -webkit-transform: translate3d(0, 0, 1px);
            transform: translate3d(0, 0, 1px);
            //end

            .menu-group {
                margin: 0 0 $grid-gutter-width-threequarters 0;
                display: flex;
                flex-direction: column;
                break-inside: avoid;
                width: 100%;
            }

            ul {
                margin: 0;
                padding: 0;
                text-indent: 0;
            }

            li {
                background: none;
                text-indent: 0;
                list-style-type: none;
                padding: 0;
                margin: 0 0 4px 0;
                width: 100%;

                &:last-of-type {
                    margin: 0;
                }

                &::before {
                    display: none;
                }

                a {
                    text-decoration: underline;
                    color: $bga-blue-links;
                    padding: 0;
                    margin: 0;
                    background: none;
                    width: 100%;
                }

                &:focus-visible {
                    outline: none;
                    border: none;
                    background: $bga-blue-background-dark;

                    a {
                        color: $bga-white;
                    }
                }

                &:hover {
                    background: $bga-blue-background-dark;

                    a {
                        color: $bga-white;
                    }
                }

                &.nav-level-2 {
                    a {
                        font-weight: 700;
                        padding: 4px 4px 4px 4px;
                    }
                }

                &.nav-level-3 {
                    font-size: 0.9375rem;
                    line-height: 1.3;

                    a {
                        font-weight: 400;
                        text-decoration: none;
                        font-size: 0.9375rem;
                        line-height: 1.3;
                        padding: 4px 4px 4px $grid-gutter-width-half;
                        background: url(../../assets/Chevron-right-bluelinks.svg) no-repeat;
                        background-position: left 4px top 10px;
                        background-size: 5px 8px;
                    }

                    &:focus,
                    &:focus-visible,
                    &:hover {
                        a {
                            background-image: url(../../assets/Chevron-right-white.svg);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md-max) {
    .mainnavigation {
        .nav {
            display: flex;

            .submenu {
                columns: 3 210px;
            }

            li {
                &.nav-level-1 {
                    margin-right: 0;
                }

                span {
                    display: block;
                }
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    .mainnavigation {
        margin-top: 0;

        .navbar {
            display: none;
            margin: 0;
            padding: 0;

            &.open {
                display: block;
            }

            .row {
                margin: 0;
                padding: 0;
            }

            .nav-wrapper {
                padding: 0;
                margin: 0;
                display: block !important;
            }
        }

        .nav {
            /* LEVEL 1 */
            width: 100%;
            display: block !important;
            margin: 0;

            .submenu-wrapper {
                display: block;
            }

            li {
                display: block;
                width: 100%;
                padding: 0;
                cursor: pointer;
                background-color: $bga-blue-background-light;
                border: none;
                border-radius: 0;
                border-bottom: $bga-white solid 1px;

                &:last-of-type {
                    border-bottom: $bga-white solid 1px;
                }

                &.nav-level-1 {
                    padding: 12px $grid-gutter-width-half;
                    height: auto;
                    border-radius: 0 !important;

                    &.nav-dropdown {
                        padding: 12px $grid-gutter-width-half;
                        background-size: 14px 9px;
                        background-position: right 16px center;
                    }
                }

                &.nav-level-1.nav-dropdown {
                    padding: 12px $grid-gutter-width-half;
                    position: relative;

                    &.open {
                        background-color: $bga-blue-background-light;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                        border: none;
                    }

                    .nav-level-1-text {
                        display: inline;
                    }
                    .nav-level-1-icon {
                        display: inline;
                        float: right;
                    }
                }
            }

            /* LEVEL 2  */
            .submenu {
                position: relative;
                box-shadow: none;
                border-top: none;

                width: 100%;
                left: auto;
                top: auto;
                padding: 0;
                columns: unset;

                .menu-group {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                }

                li {
                    padding: 12px $grid-gutter-width-half;
                    border-bottom: $bga-white 1px solid;
                    background: $bga-blue-links;
                    margin: 0;

                    &:last-of-type {
                        margin: 0;
                    }

                    a {
                        text-decoration: none;
                        color: $bga-white;
                        padding: 0 !important;
                    }

                    &:has(+ ul) {
                        a {
                            pointer-events: none;
                            cursor: default;
                        }
                    }

                    &:focus-visible {
                        outline: none;
                        border: $bga-text-focus-border;
                        background: none !important;
                    }

                    &:hover {
                        background: $bga-blue-links;

                        a {
                            color: $bga-white;
                        }
                    }

                    &.nav-level-2 {
                        a {
                            font-weight: $font-weight-semibold;
                        }

                        + ul {
                            display: none;
                            margin-top: 0;
                        }

                        &.nav-dropdown,
                        &.nav-dropdown:hover {
                            padding: 12px $grid-gutter-width-half;

                            &.open {
                                + ul {
                                    display: block;

                                    li.nav-level-3 {
                                        background: $bga-blue-footer;
                                        border-bottom: $bga-white solid 1px;
                                        padding: 12px $grid-gutter-width-half;

                                        a {
                                            background: none;
                                            padding: 0;
                                            line-height: 1.75;
                                        }
                                    }
                                }
                            }
                        }

                        .nav-level-2-icon {
                            display: inline;
                            float: right;
                        }
                    }
                }
            }
        }
    }
}
