@import 'variables';

@mixin _react-select-indicators {
    display: none !important;
}

@mixin _react-select-control {
    border: 1px solid $bga-black !important;
    color: $bga-black;
    padding: $grid-gutter-width-onequarter;

    &[class*='__control--is-focused'] {
        border: 1px solid $bga-blue-background-dark !important;
        box-shadow: 0 0 0 1px $bga-blue-background-dark !important;
    }
}

@mixin _react-select-input-container {
    display: flex;
    justify-content: end;
    margin: 0;
    padding: 0;

    &::after {
        display: none;
    }

    > input {
        height: 48px;
        margin: 0;
        min-height: 48px;
        opacity: 1 !important;
        text-overflow: ellipsis;
        text-indent: 0;

        &:focus,
        &:focus-visible {
            box-shadow: 0 0 0 1px $bga-blue-background-dark !important;
        }

        &::after {
            content: '';
        }

        &:not([value='']) {
            padding: $grid-gutter-width-onequarter * 1.5 $grid-gutter-width * 1.5 $grid-gutter-width-onequarter * 1.5 $grid-gutter-width-half;
        }
    }
}

@mixin _react-select-single-value {
    display: block !important;
    color: $bga-black;
    z-index: 1;

    grid-area: 1/1/2/3;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    margin: 0 2px;
}

@mixin _react-select-menu {
    border: 1px solid $bga-blue;
    border-radius: 0 !important;
    background-color: $bga-white;
    z-index: 10 !important;
    margin: 0 !important;

    div[class*='__menu-list'] {
        padding: 0;
    }

    div[class*='__menu-notice'] {
        padding: $grid-gutter-width-onequarter * 1.5 $grid-gutter-width-half;
    }

    div[class*='__option'] {
        background-color: $bga-white;
        color: $bga-black;
        padding: $grid-gutter-width-onequarter * 1.5 $grid-gutter-width-half;

        .highlight {
            color: $bga-black;
            background: $bga-blue-background-light;
        }

        &[class*='__option--is-focused'] {
            background-color: $bga-blue-background-dark;
            color: $bga-white;

            .highlight {
                color: $bga-white;
                background-color: $bga-blue-background-dark;
            }
        }
    }
}
