@import '../../../styles/variables';

.field-wrapper {
    margin-bottom: $grid-gutter-width;

    .error-wrapper {
        box-shadow: 0 0 0 8px $bga-red-fail-mid;
        background: $bga-red-fail-mid;
        margin-bottom: $grid-gutter-width-half;

        input,
        select {
            border: 1px solid $bga-red-fail-dark;
            border-radius: 4px;
            box-shadow: 0 0 0 2px $bga-red-fail-dark;
        }
    }

    .field-hint-text {
        margin-bottom: $grid-gutter-width-half;
    }

    .field-error-message {
        color: $bga-red-fail-dark;
        margin-bottom: $grid-gutter-width-half;
    }
}
