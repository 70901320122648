@import '../../styles/variables';

.auth-container-non-modal {
    border-left: 8px solid $nb-grey-mid;
    background: $bga-grey-lighter;
    padding: $grid-gutter-width-threequarters;
    margin-bottom: $grid-gutter-width-threequarters;
}

.auth-container-modal {
    background: $bga-white;
}
