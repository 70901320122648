@import '../../styles/variables';

.disclaimer {
    border: 1px solid $bga-blue-black-light;
    border-style: solid none;

    > details {
        padding: 0;
        position: relative;

        &.standard > summary {
            pointer-events: none;

            .arrow-down-icon::after {
                display: none;
            }
        }

        &:not(.standard) {
            &[open] .arrow-down-icon::after {
                transform: rotate(180deg);
            }
        }

        > summary {
            list-style: none;
            padding: $grid-gutter-width-onequarter 0;

            &:hover {
                text-decoration: underline;
            }

            &:focus-visible {
                box-shadow: inset 0 0 0 1px $bga-blue-background-dark;
                border-radius: 4px;
            }

            &.disclaimer-summary {
                display: flex;
                align-items: center;
                position: relative;

                > * {
                    font-size: $font-size-base * 0.8125;
                    font-weight: $font-weight-semibold;
                    line-height: $font-size-base * 1.4;
                }

                .disclaimer-icon {
                    flex: none;
                    margin-right: $grid-gutter-width-onequarter;
                }

                .arrow-down-icon {
                    position: absolute;
                    right: 0;
                }
            }

            + div {
                padding: $grid-gutter-width-onequarter 0 $grid-gutter-width-onequarter $grid-gutter-width * 0.875;

                > * {
                    font-size: $font-size-base * 0.8125;
                    line-height: $font-size-base * 1.4;
                    margin-bottom: $grid-gutter-width-half;

                    > li {
                        &:not(:last-child) {
                            margin-bottom: $grid-gutter-width-onequarter !important;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0 !important;
                        padding-bottom: 0 !important;

                        > li:last-child {
                            margin-bottom: 0 !important;
                        }
                    }
                }
            }
        }
    }
}
