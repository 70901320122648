@import '../../styles/variables';

@mixin _expandCollapseTreeIcons() {
    ul.tree li:not(.sole-root).has-children:before {
        cursor: pointer;
        display: initial;
        content: '';
        top: 0;
        left: 0;
        height: 28px;
        width: 28px;
        position: absolute;
        background-image: url('../../assets/Ellipse-open.png');
        background-size: 28px;
        background-position-y: -4px;
        z-index: 2;
    }

    ul.tree li:not(.sole-root).is-expanded.has-children:before {
        display: initial;
        content: '';
        top: 0;
        left: 0;
        height: 27px;
        width: 27px;
        position: absolute;
        background-image: url('../../assets/Ellipse-close.svg');
        background-size: 27px;
        background-position-y: -15px;
        z-index: 2;
        cursor: pointer;
    }

    ul.tree li:not(.sole-root).is-expanded.is-focused.has-children:before {
        display: initial;
        content: '';
        top: 0;
        left: 0;
        height: 27px;
        width: 27px;
        position: absolute;
        background-image: url('../../assets/Ellipse-close-selected.svg');
        background-size: 27px;
        background-position-y: -15px;
        z-index: 2;
        cursor: pointer;
    }

    ul.tree.tree-tablet li:not(.sole-root).has-children:before {
        height: 24px;
        width: 24px;
        background-size: 24px;
        background-position-y: -4px;
        background-image: url('../../assets/Tablet-ellipse-open.svg');
    }

    ul.tree.tree-mobile li:not(.sole-root).has-children:before {
        height: 20px;
        width: 20px;
        background-size: 20px;
        background-position-y: -3px;
        background-image: url('../../assets/Mobile-ellipse-open.svg');
    }

    ul.tree.tree-tablet li:not(.sole-root).is-expanded.has-children:before {
        height: 24px;
        width: 24px;
        background-size: 24px;
        background-position-y: -14px;
        background-image: url('../../assets/Tablet-ellipse-close.svg');
    }

    ul.tree.tree-tablet li:not(.sole-root).is-expanded.is-focused.has-children:before {
        height: 24px;
        width: 24px;
        background-size: 24px;
        background-position-y: -14px;
        background-image: url('../../assets/Tablet-ellipse-close-selected.svg');
    }

    ul.tree.tree-mobile li:not(.sole-root).is-expanded.has-children:before {
        height: 20px;
        width: 20px;
        background-size: 20px;
        background-position-y: -11px;
        background-image: url('../../assets/Mobile-ellipse-close.svg');
    }

    ul.tree.tree-mobile li:not(.sole-root).is-expanded.is-focused.has-children:before {
        height: 20px;
        width: 20px;
        background-size: 20px;
        background-position-y: -11px;
        background-image: url('../../assets/Mobile-ellipse-close-selected.svg');
    }

    ul.tree.tree-tablet li:not(.sole-root).has-children,
    ul.tree.tree-mobile li:not(.sole-root).has-children {
        line-height: 24px;
    }
}
