@import '../../../styles/variables';

.navigate-further {
    .laws-and-regs-tree-base {
        ul.tree {
            li:not(.has-selection) > div.data div.content,
            li li:not(.has-selection) > div.data div.content {
                text-decoration: underline;
                text-underline-offset: 4px;
            }

            li div.data a,
            li li div.data a {
                display: inline-block;
                border-bottom: 2px solid transparent;
                font-weight: 600;
            }

            li div.data a:focus,
            li li div.data a:focus {
                border-bottom: 2px solid $bga-blue-focus;
            }
        }
    }
}
