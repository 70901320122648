@import '../../styles/variables';

/* Table */
.table {
    border-collapse: collapse;
    border-top: 4px solid $nb-grey-mid;
    width: 100%;
    background-color: $bga-white;
    overflow-y: hidden;
    margin-bottom: $grid-gutter-width;
    overflow-x: auto;
}

.table th {
    font-weight: 600;
    padding: $grid-gutter-width-half;
    border-bottom: 2px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    vertical-align: bottom;
    width: auto;
    text-align: left;
    color: #212529;
}

.table tr {
    background-color: $bga-white-smoke;
    color: $bga-black;
}

.table tbody tr {
    background-color: $bga-white;
    border-bottom: 1px solid $bga-white-smoke;
}

.table td {
    border-top: 1px solid #dee2e6;
    vertical-align: top;
    font-weight: 400;
    padding: $grid-gutter-width-half;
}

.table caption {
    caption-side: top;
    text-align: left;
    font-weight: 600;
}

.table tfoot * {
    font-size: 14px;
    line-height: 24px;
}
/* Icon */

.arrow-icon {
    float: right;
    padding: 0;
    display: none;
}

.showGroup .arrow-icon {
    transform: scaleY(-1);
}
/* Media Query */

@media (max-width: $screen-sm) {
    .table > thead > tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
        display: table;
        width: 100%;
        border-bottom: 1px solid $nb-grey-mid;
    }

    .table tbody tr {
        border-bottom: 1px solid $nb-grey-mid;
    }

    .table > tbody > tr > td {
        display: none;
        position: relative;
        white-space: normal;
        text-align: left;
        border-top: 0;
        padding: 1rem !important;
        background: $bga-grey-lighter;
        border-top: 1px solid $nb-grey-mid;
    }

    .table .groupParent > td > span {
        width: 100%;
    }

    .table > tbody > tr > td:first-of-type {
        visibility: visible;
        height: auto;
        opacity: 1;
        background-position: right 16px center;
        background-size: 16px 10px;
        font-weight: 600;
        display: block;
        cursor: pointer;
        border-bottom-width: 0;
    }

    .table > tbody > tr > td:first-of-type {
        background: $bga-white;
    }

    .table > tbody > tr > td:not(:first-of-type) {
        padding: $grid-gutter-width-half !important;
    }

    .table td.showGroup {
        display: flex;
        transition: all 0.2s;
        background: $bga-grey-lighter;
        min-height: 54.38px;
        width: 100%;
    }

    .table > tbody > tr > td::before {
        text-align: left;
        content: attr(data-title);
        width: 40%;
    }

    .table > tbody > tr > td:first-of-type::before {
        content: '';
    }

    .table > tbody > tr > td:not(:first-of-type)::before {
        padding-right: 10% !important;
    }

    .arrow-icon {
        display: block;
    }

    .table tfoot td,
    .table tfoot th {
        cursor: default !important;
    }
}
