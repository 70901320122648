@import '../../../styles/variables';

.filter-checkbox {
    width: 100%;
    margin: -$grid-gutter-width-onequarter 0 $grid-gutter-width-onequarter * 1.5;
    padding-top: 0;

    .filter-checkbox-item {
        display: flex;
        flex-wrap: wrap;

        .filter-checkbox-field-inner {
            width: fit-content;
            line-height: 28px;
            display: block;
            padding: 0;
            background: $bga-white;

            .filter-checkbox-label {
                margin: 0;
                border: none;
                padding: $grid-gutter-width-onequarter;
            }

            input {
                &:focus + label {
                    outline: none;
                    border-radius: 4px;
                    border: 1px solid $bga-blue-background-dark;
                    box-shadow: 0 0 0 1px $bga-blue-background-dark;
                }

                &:not(:checked) {
                    position: absolute;
                    left: -9999px;
                }

                &:checked {
                    &:disabled + label {
                        &:before {
                            outline: none;
                            border: solid 1px $bga-grey;
                            background-color: $bga-white-smoke;
                            cursor: default;
                        }

                        color: $bga-oslo-grey;
                        cursor: default;
                    }

                    position: absolute;
                    left: -9999px;
                }

                &:disabled {
                    &:not(:checked) + label {
                        &:before {
                            outline: none;
                            border: solid 1px $bga-grey;
                            background-color: $bga-white-smoke;
                            cursor: default;
                        }

                        color: $bga-oslo-grey;
                        cursor: default;
                    }
                }

                &:checked + label {
                    position: relative;
                    cursor: pointer;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: $bga-blue-links;
                    font-weight: $font-weight-semibold;

                    &:before {
                        content: '';
                        min-width: 28px;
                        height: 28px;
                        margin-right: 14px;
                        background: $bga-white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-sizing: border-box;
                        border: 3px solid $bga-blue-links;
                    }

                    svg {
                        display: block;
                    }
                }

                &:not(:checked) + label {
                    position: relative;
                    cursor: pointer;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: $bga-black;
                    font-weight: normal;

                    &:before {
                        border: solid 1px $bga-black;
                        content: '';
                        min-width: 28px;
                        height: 28px;
                        margin-right: 14px;
                        background: $bga-white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-sizing: border-box;
                    }
                }
            }

            svg {
                display: none;
                position: absolute;
                left: 15px;
            }
        }

        .filter-checkbox-label {
            border: solid 1px $bga-black;
            width: 100%;
            padding: 0;
            box-sizing: border-box;
            margin-bottom: $grid-gutter-width-onequarter;
        }

        .filter-checkbox-item {
            display: flex;
            flex-wrap: wrap;

            .filter-checkbox-field-inner {
                width: 80%;
            }
        }

        .filter-checkbox-state {
            width: 20%;
            right: 16px;
            display: flex;

            .filter-checkbox-count {
                color: $bga-black;
            }

            &:focus {
                outline-color: $bga-blue;
            }
        }
    }
}
