@import '../../styles/variables';

.site-search-results-container {
    .site-search-tile-wrapper {
        border: 1px solid $bga-carolina-blue;
        border-left: 4px solid $bga-blue-background-dark;

        padding: $grid-gutter-width-threequarters;
        margin-bottom: $grid-gutter-width;

        a {
            display: inline-block;
            margin-bottom: $grid-gutter-width-threequarters;
            font-size: $font-size-base * 1.125;
        }
        .description {
            font-size: $font-size-base * 0.875;
        }
    }
}
