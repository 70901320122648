@import './styles/variables';

.skip-link {
    .bypass-block {
        display: inline-block;
        height: 1px;
        left: -999px;
        margin: 8px;
        overflow: hidden;
        position: absolute;
        top: auto;
        width: 1px;
        z-index: -999;
    }

    .bypass-block:focus {
        color: $bga-black;
        height: auto;
        left: 0;
        overflow: auto;
        padding: 5px;
        position: static;
        text-align: left;
        width: auto;
        z-index: 999;
        border-radius: 3px;
        box-shadow: 0 0 0 2px $bga-mariner;
        outline: none;
    }

    .bypass-block:link,
    .bypass-block:visited,
    .bypass-block:hover,
    .bypass-block:active {
        text-decoration: none;
        font-weight: normal;
    }
}
