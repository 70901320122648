@import '../../styles/variables';

.accordion {
    .accordion-container {
        padding: 0 0 $grid-gutter-width 0;
    }

    .accordion-group-toggle.visible {
        display: flex;
    }

    .accordion-group-toggle {
        display: none;
        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center;
        border-bottom: 0.0625rem solid $bga-grey-lighter;
        justify-content: flex-end;
        padding: 1.5rem 2rem 1.5rem 1.5rem;

        button.accordion-group-toggle-content {
            border: 0;
            padding: 0;
            background: none;
            cursor: pointer;
            min-width: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2rem;

            .accordion-group-toggle-inner {
                display: inline;
            }

            h5 {
                margin-bottom: 0;
                color: $bga-darker-grey;
                font-weight: 600;
                margin: 0 0.5rem 0 0;
                min-width: 90px;
                display: inline;

                span {
                    -webkit-transition: all 0.3s ease 0s;
                    transition: all 0.3s ease 0s;
                }
            }

            img {
                max-width: 16px;
                margin-left: 5px;
            }

            &:hover h5 span {
                -webkit-box-shadow: inset 0 -0.11rem 0 $bga-darker-grey;
                box-shadow: inset 0 -0.11rem 0 $bga-darker-grey;
                line-height: 1.38;
            }
        }
    }
}
