@import '../../../styles/variables';

.pagination-links {
    /* Container */

    .pagination {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            color: $bga-blue-light;
            padding: 0 6px;
            margin: 0 10px;
            text-decoration: none;
        }
        /* Links */
        button.active {
            color: $bga-black;
            background: $bga-blue-chalk;
            padding: 0 6px;
            border-radius: 2px;
        }

        button:hover {
            border-radius: 2px;
            padding: 0 6px;
            border: 2px solid transparent;
        }

        button:focus {
            outline: none;
            border: 2px solid $bga-mariner;
        }

        button:active {
            color: $bga-black;
            background: $bga-grey-dark;
            border-radius: 2px;
        }

        button:active .svg-container svg path {
            fill: $bga-black;
        }

        button:disabled {
            color: $bga-oslo-grey;
            border-color: transparent;
            box-shadow: none;
            background-color: transparent;
            cursor: default;
        }

        .svg-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
        }
        /* Icons */
        .first .svg-container,
        .previous .svg-container {
            height: 20px !important;
            width: 20px !important;
            padding-right: 8px;
        }

        .last .svg-container,
        .next .svg-container {
            height: 16px !important;
            width: 16px !important;
            padding-left: 8px;
        }

        .previous .svg-container,
        .next .svg-container {
            height: 16px !important;
            width: 16px !important;
        }

        .last .svg-container,
        .first .svg-container {
            height: 20px !important;
            width: 20px !important;
        }

        .previous .svg-container svg,
        .next .svg-container svg {
            path {
                fill: $bga-blue-light;
            }
        }

        .last .svg-container svg,
        .first .svg-container svg {
            path {
                fill: $bga-blue-light;
            }
        }

        .previous:disabled .svg-container svg,
        .next:disabled .svg-container svg {
            path {
                fill: $bga-oslo-grey;
            }
        }

        .last:disabled .svg-container svg,
        .first:disabled .svg-container svg {
            path {
                fill: $bga-oslo-grey;
            }
        }
    }
}
