@import '../../styles/variables';

.account-wrapper {
    min-height: 52px;

    @media screen and (min-width: $screen-sm) {
        position: relative;
    }

    button.link {
        min-height: 56px;
        padding: $grid-gutter-width-onequarter * 0.5 $grid-gutter-width-half * 0.75;
        color: $bga-white;
        text-decoration: none;
        display: flex;
        align-items: center;

        &:hover,
        &:active {
            box-shadow: none;
            color: $bga-white !important;
            text-decoration: underline;
            text-decoration-color: $bga-white !important;

            &.person-icon::after {
                text-decoration: underline;
            }
        }

        &:focus-visible,
        &:focus {
            border: 2px solid $bga-white;
            border-radius: 4px;
            box-shadow: none;
            outline: none;
            color: $bga-white !important;
        }

        &.person-icon {
            &::after {
                content: ' to Austrade';
                display: inline-block;
            }

            &::after,
            &::before {
                @media screen and (max-width: $screen-sm-max) {
                    display: none;
                }
            }
        }

        .my-account {
            @media screen and (max-width: $screen-sm-max) {
                display: none;
            }

            &.arrow-down-icon::after {
                background-color: $bga-white;
            }

            &.open {
                &.arrow-down-icon::after {
                    transform: rotate(180deg);
                }
            }
        }

        .high-priority-icon {
            position: relative;
            top: -4px;
            right: -22px;
            margin-right: -($grid-gutter-width * 0.875);
            z-index: 100;
        }

        .account-initials {
            @media screen and (max-width: $screen-sm-max) {
                margin-right: 0;
            }
        }

        @media screen and (max-width: $screen-sm-max) {
            padding: $grid-gutter-width-onequarter * 0.25 $grid-gutter-width-onequarter * 0.5;
            min-width: 40px;
            min-height: 52px;
            width: auto;
        }
    }

    .account-initials {
        position: relative;
        cursor: pointer;
        margin: 0;
        margin-right: $grid-gutter-width-onequarter;
        background: $bga-white;
        color: $bga-blue-black-dark !important;
        display: inline-block;
        padding: $grid-gutter-width-onequarter * 0.5;
        border-radius: 50%;
        font-weight: $font-weight-semibold;
        width: 40px;
        line-height: 34px;

        &:focus,
        &:active,
        &:focus-visible {
            outline: none !important;
            border: 1px solid $bga-white !important;
            box-shadow: 0 0 0 1px $bga-white !important;
        }
    }

    .account-menu {
        border: 1px solid $bga-blue-black-light;
        box-shadow: 2px 2px 3px 0 rgba(51, 51, 51, 0.4);
        position: absolute;
        background: $bga-white;
        padding: $grid-gutter-width-threequarters;
        color: $bga-black;
        z-index: 101;
        right: 0;
        margin: 0;

        @media screen and (max-width: $screen-xs-max) {
            left: 0;
            margin: 0 $grid-gutter-width-threequarters;
        }

        @media screen and (min-width: $screen-sm) {
            min-width: 375px;
            max-width: 400px;
        }

        & > * {
            width: 100%;
            display: flex;
            align-items: center;

            div,
            p {
                color: $bga-black;
            }
        }

        .account-initials {
            flex: none;
            cursor: default;
            background: $bga-blue-footer;
            color: $bga-white !important;
            padding: $grid-gutter-width-onequarter * 0.25;
            text-align: center;
            font-size: $font-size-base * 0.875;
            width: 36px;
            margin-right: $grid-gutter-width-half;

            &:hover {
                cursor: default !important;
                background: $bga-blue-footer !important;
            }
        }

        .account-name {
            font-weight: $font-weight-semibold;
            text-overflow: ellipsis;
            text-wrap-mode: nowrap;
            overflow: hidden;
        }

        .menu-items-container {
            margin: $grid-gutter-width-threequarters 0;
            padding: $grid-gutter-width-half $grid-gutter-width-onequarter;
            border-top: 2px solid $bga-blue-black-light;
            border-bottom: 2px solid $bga-blue-black-light;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            > .menu-item {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: $grid-gutter-width-half;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .menu-item {
            > .icon {
                height: 28px;
                width: 28px;

                &.signout-icon {
                    margin-left: $grid-gutter-width-onequarter;
                }
            }

            .icon-placeholder {
                height: 28px;
                width: 28px;
                flex: none;
            }

            .menu-title {
                padding-left: $grid-gutter-width-half;
            }

            &:focus-visible {
                outline: none !important;
                border: 1px solid $bga-blue-background-dark !important;
                box-shadow: 0 0 0 1px $bga-blue-background-dark !important;
            }

            &:hover {
                background-color: $bga-blue-background-dark;
                color: $bga-white;
                font-weight: $font-weight-semibold !important;
                text-decoration: none !important;
                cursor: pointer;

                > * {
                    color: $bga-white;
                }

                > .icon {
                    filter: invert(100%) sepia(100%) saturate(100%) brightness(120%) contrast(200%);
                }
            }
        }
    }
}
