@import '../../../styles/variables';

.radio-button {
    display: inline-block;
    width: auto;
    padding: 10px 40px 10px 49px;
    position: relative;
    color: $bga-black;
    cursor: pointer;
    margin-top: 0;
    font-weight: normal;

    &.selected {
        color: $bga-blue-links;
        font-weight: $font-weight-semibold;
    }

    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        display: inline-block;
        height: 24px;
        left: 15px;
        padding: 0;
        position: absolute;
        width: 24px;
        background: url(../../../assets/radio-not-selected.svg) no-repeat;
        background-size: 24px 24px;
        outline: none;

        &:checked {
            background: url(../../../assets/radio-selected.svg) no-repeat;
        }
    }
}
