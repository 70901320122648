@import '../../styles/variables';

.sector-explorer-box-form-field-container {
    box-sizing: border-box;
    margin-bottom: 30px;

    .sector-explorer-box-container {
        display: block;
        width: 100%;
    }
}
