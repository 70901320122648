@import './bgaVariables';

// Font
$font-family-base: $font-family-sans-serif;

// BGA Colour Overrides
//https://colors.artyclick.com/color-name-finder/
$bga-white-smoke: #f5f5f5;
$bga-hibiscus: #a42079;
$bga-mariner: #2f70bf;
$bga-yellow-blue-yonder: #748fb8;
$bga-oslo-grey: #8e8e8e;
$bga-silver-polish: #c7c7c7;
$bga-blue: #4569a0;
$bga-blue-light: #254f90;
$bga-blue-chalk: #e5edf7;
$bga-pattens-blue: #ddeeff;
$bga-grey: #e2e2e2;
$bga-grey-lighter: #ebebeb;
$bga-grey-dark: #b3c2d8;
$bga-darker-grey: #666666;
$bga-darkest-grey: #4c4c4c;
$bga-yellow: #edca72;
$bga-yellow-tint: #e1aa21;
$bga-light-blue-grey: #b8cde7;
$bga-carolina-blue: #9cb6db;
$bga-jungle-mist: #9ed2d3;
$bga-astra: #fdebb3;
$bga-jasmine: #fadd82;
$bga-tropical-blue: #c7d9ee;
$bga-vanilla-ice: #f7d7d7;
$bga-purple-opacity-40: rgb(177, 168, 227, 0.4);
$bga-purple-opacity-30: rgb(177, 168, 227, 0.3);

//breakpoints for media queries
$screen-below-xs: 349px;

$screen-xs: 350px;
$screen-xs-max: 575px;

$screen-sm: 576px;
$screen-sm-max: 767px;

$screen-md: 768px;
$screen-md-max: 991px;

$screen-lg: 992px;

$screen-xl: 1200px;

//container
$container-xxl: 1320px;

//grid gutter widths
$grid-gutter-width-threequarters: $grid-gutter-width * 0.75;
$grid-gutter-width-half: $grid-gutter-width * 0.5;
$grid-gutter-width-onequarter: $grid-gutter-width * 0.25;
$grid-gutter-width-double: $grid-gutter-width * 2;
