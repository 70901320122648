@import '../../styles/variables';

.personalised-search-panel-container {
    .filtergroup-form-field-container {
        border-bottom: none;
    }

    .personalisation-clear-filters {
        .clear {
            p {
                color: $bga-blue-light;
                box-shadow: none;
                text-decoration: none;
                border-bottom: solid 0.2rem $bga-blue-focus;
                background-color: transparent;
                margin-top: 0.5rem;
                padding-right: 1.5rem;
                display: inline-block;
                position: relative;
                font-weight: bold;
            }

            span {
                height: 1rem;
                width: 1rem;
                display: inline-block;
                background: url(../../assets/refresh.svg) no-repeat;
                margin-left: 0.5rem;
                position: absolute;
                top: 0.3rem;
                right: 0;
            }

            &:active,
            &:hover,
            &:focus,
            &:focus:hover {
                background-color: transparent;
                box-shadow: initial;
            }
        }
    }

    .personalisation-filter-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
