@import '../../styles/variables';

.tariff-notes-heading {
    margin-top: 0.5em;
}

.tariff-note {
    p {
        padding-bottom: 0.5em;

        @media (max-width: $screen-sm) {
            line-height: inherit;
        }
    }

    padding-top: 0.5em;

    &:not(:last-of-type) {
        border-bottom: 1px solid #dee2e6;
    }
}
