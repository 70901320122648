@import '../../styles/variables';

.crmForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
        margin-top: 20px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.lp-form-button {
    border-radius: 6px;
    padding: $grid-gutter-width-onequarter $grid-gutter-width;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    transition-duration: 0.4s, 0.4s;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: solid 2px $bga-blue-light;
    background-color: $bga-blue;
    color: $bga-white;
    text-decoration: none;
    height: 44px;
    margin-top: 30px;
    text-transform: none;
}

.lp-form-button:hover {
    background: $bga-blue-light;
    color: $bga-white;
}

div[data-editorblocktype='Field-checkbox'] {
    .lp-form-field {
        display: flex;
    }

    input {
        margin-top: 10px;
    }

    label {
        margin-left: 5px;
    }
}

.lp-form-fieldInput[type='checkbox'] {
    transform: scale(2);
    margin-bottom: 10px;
    margin-left: 5px;
}

.lp-ellipsis {
    color: $bga-black;
    font-size: small;
}
