@import '../../styles/variables';

.question-container {
    .step {
        .StepProgress {
            position: relative;
            padding-left: 45px;
            list-style: none;
            padding-bottom: 28px;

            &-item {
                position: relative;
                counter-increment: list;
                min-height: 100px;
                padding-left: $grid-gutter-width;

                &::before {
                    display: inline-block;
                    content: '';
                    position: absolute;
                    left: -20px;
                    width: 10px;
                    height: 100%;
                    border-left: 2px solid #ccc;
                    text-align: center;
                    color: $bga-black;
                }

                &::after {
                    display: inline-block;
                    position: absolute;
                    top: -4px;
                    left: -43px;
                    width: 48px;
                    height: 48px;
                    border: 2px solid #ccc;
                    border-radius: 50%;
                    background-color: $bga-white;
                    font-size: 26px;
                    padding-top: 6px;
                    padding-left: 13px;
                }

                &:not(:last-child) {
                    padding-bottom: 20px;
                }

                &:last-child {
                    min-height: 0;

                    &::before {
                        border-left: none;
                    }

                    &::after {
                        padding-left: 7px;
                    }
                }

                &.current {
                    &::before {
                        display: block;
                    }

                    &::after {
                        margin-top: $grid-gutter-width-onequarter;
                        width: 48px;
                        height: 48px;
                        left: -40px;
                        font-size: 26px;
                        text-align: center;
                        background: $bga-blue-light;
                        border: 3px solid $bga-blue-light;
                        color: $bga-white;
                        padding-left: 0;
                    }
                }
            }

            .radio {
                width: 200px;
                display: block;
                position: relative;
                font-weight: normal;
                border: solid 1px #495c74;
                margin-bottom: 10px;
                padding: 10px 40px;
                background-color: $bga-white;

                label {
                    cursor: pointer;
                    padding-left: $grid-gutter-width-onequarter;
                    margin-top: 5px;
                }

                input[type='radio'] {
                    background: url('../../assets/notselected.svg') no-repeat;
                    background-size: 24px 24px;
                    content: '';
                    display: inline-block;
                    position: absolute;
                    left: 16px;
                    height: 24px;
                    width: 24px;
                    top: 15px;
                }

                input[type='radio']:checked::before {
                    content: url('../../assets/selected.svg');
                }
            }

            .question {
                &:active {
                    h2 {
                        text-decoration: none;
                    }
                }
            }

            .filtergroup-form-field-container-radiobuttongroup {
                border: 0;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    .question-container {
        .step {
            .StepProgress {
                &-item {
                    padding-left: 0;

                    &.current {
                        &::after {
                            padding-top: 0;
                            margin-top: $grid-gutter-width-onequarter * 1.25;
                            width: 32px;
                            height: 32px;
                            font-size: $font-size-base;
                        }
                    }
                }
            }
        }
    }
}
