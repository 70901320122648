@import '../../styles/variables';

.pull-quote {
    padding: $grid-gutter-width 0 ($grid-gutter-width * 1.5) 0;

    .quote-content {
        display: flex;
        flex-wrap: nowrap;

        .quote-image {
            img {
                border-radius: 50%;
                width: 150px;
                height: 150px;
                object-fit: cover;
            }
        }
        .quote-text {
            padding-left: $grid-gutter-width-threequarters;

            blockquote {
                font-size: 1.375rem;
                font-style: italic;
                text-indent: -44px;
                margin-left: 44px;
                line-height: 33px;

                &:before {
                    content: '';
                    height: 17px;
                    width: 28px;
                    margin-right: $grid-gutter-width-half;
                    display: inline-block;
                    -webkit-mask-image: url(../../assets/icon-quote-left.svg);
                    mask-image: url(../../assets/icon-quote-left.svg);
                    mask-repeat: no-repeat;
                    -webkit-mask-repeat: no-repeat;
                }
                &:after {
                    content: '';
                    height: 17px;
                    width: 28px;
                    display: inline-block;
                    margin-left: $grid-gutter-width-half;
                    -webkit-mask-image: url(../../assets/icon-quote-right.svg);
                    mask-image: url(../../assets/icon-quote-right.svg);
                    mask-repeat: no-repeat;
                    -webkit-mask-repeat: no-repeat;
                }
            }

            .author {
                font-size: 0.875rem;
                margin-left: 44px;
                line-height: 24.5px;
            }
        }
    }

    .quote-content:has(div.quote-image) {
        .quote-text {
            padding-left: $grid-gutter-width * 0.375;
        }
    }

    &.purple {
        .quote-content:not(:has(div.quote-image)) {
            border-left: 8px solid $bga-purple-highlight-dark;
        }
        blockquote {
            &:before,
            &:after {
                background-color: $bga-purple-highlight-mid;
            }
        }
    }

    &.teal {
        .quote-content:not(:has(div.quote-image)) {
            border-left: 8px solid $bga-teal-highlight-dark;
        }
        blockquote {
            &:before,
            &:after {
                background-color: $bga-teal-highlight-mid;
            }
        }
    }
    &.green {
        .quote-content:not(:has(div.quote-image)) {
            border-left: 8px solid $bga-green-highlight-dark;
        }
        blockquote {
            &:before,
            &:after {
                background-color: $bga-green-highlight-mid;
            }
        }
    }
    &.orange {
        .quote-content:not(:has(div.quote-image)) {
            border-left: 8px solid $bga-orange-highlight-dark;
        }
        blockquote {
            &:before,
            &:after {
                background-color: $bga-orange-highlight-mid;
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    .pull-quote {
        padding: $grid-gutter-width-threequarters 0 $grid-gutter-width 0;

        .quote-content {
            flex-wrap: wrap;

            .quote-image {
                display: flex;
                justify-content: center;
                width: 100%;

                img {
                    margin-bottom: ($grid-gutter-width * 0.375);
                }
            }

            .quote-text {
                blockquote {
                    font-size: 1.125rem;
                    line-height: 27px;
                }
            }
        }

        .quote-content:has(div.quote-image) {
            .quote-text {
                padding-left: 0;
            }
        }

        .quote-content:not(:has(div.quote-image)) {
            .quote-text {
                padding-left: $grid-gutter-width-half;
            }
        }
    }
}
