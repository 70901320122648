@import '../../styles/variables';

.event-pathway-wrapper {
    margin-bottom: $grid-gutter-width;
    display: block;

    a[href*="//"]
    {
        &:after {
            padding-left: 0;
            content: '';
        }
    }

    a {
        border-bottom: 0;
        font-weight: normal;
        text-decoration: none !important;
        display: block;
        height: 100%;
        background-color: $bga-white;

        .event-pathway-card {
            height: 100%;
            padding: $grid-gutter-width-threequarters;
            background-color: $bga-white;
            color: $bga-black;
            position: relative;
            border: 1px solid $bga-carolina-blue;
            border-left: 4px solid;
            border-left-color: $bga-blue-background-dark;

            .event-pathway-card-content {
                padding-bottom: 0;

                .event-pathway-card-category {
                    text-align: left;
                    text-transform: uppercase;
                    color: $bga-black;
                    margin-bottom: $grid-gutter-width-threequarters;
                }

                .event-pathway-card-title {
                    margin-bottom: $grid-gutter-width-threequarters;
                    text-decoration: underline;
                    color: $bga-blue-links;
                    line-height: 27px !important;
                    font-size: $font-size-base * 1.125 !important;
                }

                .event-pathway-card-description {
                    margin-bottom: $grid-gutter-width-threequarters;

                    * {
                        text-decoration: none;
                        font-weight: normal;
                        color: $bga-black;
                        line-height: 24.5px !important;
                        font-size: $font-size-base * 0.875 !important;
                    }
                }
            }

            .event-pathway-footer {
                display: flex;
                gap: $grid-gutter-width;
                width: 100%;
                align-items: center;
                color: $bga-black;
            }
        }

        &:hover,
        &:active,
        &:focus {
            cursor: pointer;
            box-shadow: none !important;

            .event-pathway-card {
                background: $bga-blue-background-extralight;

                .tag {
                    background: $bga-white !important;
                }
            }
        }
    }
}
