@import '../../styles/variables';

.accordion-guide-pathway {
    .accordion-item-guide-pathway {
        margin-bottom: $grid-gutter-width-threequarters;

        .accordion-item-tile {
            cursor: pointer;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            position: relative;
            transition: background-color 0.5s;

            .accordion-item-tile-inner {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: stretch;
                box-sizing: border-box;
                border: 1px solid $bga-blue-border;
                border-left-width: 8px;

                .guide-accordion-image {
                    width: 250px;
                    display: flex;
                    flex-shrink: 0;
                    background: $bga-white;
                    margin: auto;
                    overflow: hidden;
                }

                .guide-accordion-image img {
                    overflow: hidden;
                    width: 100%;
                    max-width: 100%;
                    object-fit: cover;
                    height: auto;
                }

                .guide-accordion-image img svg {
                    vertical-align: middle;
                }
                .accordion-item-tile-inner-title {
                    width: calc(100% - 342px);

                    p {
                        margin-bottom: 0;
                    }
                }

                .arrow {
                    border: none !important;
                    align-self: stretch;
                    display: flex;
                    align-items: stretch;
                    width: 92px;

                    .float-button-content {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        height: auto;
                        margin: auto;

                        img {
                            width: 28px;
                            height: 18px;
                            flex-shrink: 0;
                        }
                    }
                }
            }

            &:focus-visible {
                border-radius: 3px;
                box-shadow: 0 0 0 2px $bga-mariner;
                outline: none;
            }
        }

        .accordion-item-tile:hover {
            h3 {
                text-decoration: underline;
            }
        }

        .accordion-item-content {
            padding: $grid-gutter-width-threequarters $grid-gutter-width-threequarters 0 $grid-gutter-width-threequarters;
            display: none;
            transition-property:
                transform,
                -webkit-transform;
            transition-duration: 0.4s, 0.4s;
            transition-timing-function: ease, ease;
            transition-delay: 0s, 0s;
            max-height: 0;
            border: 1px solid $bga-blue-border;
            border-top-width: 0;
            border-left-width: 8px;
        }

        .accordion-item-content.visible {
            display: block;
            max-height: none;

            div {
                .pathway-list {
                    padding-bottom: 0 !important;
                }
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    .accordion-guide-pathway {
        .accordion-item-tile {
            .accordion-item-tile-inner {
                border-left-width: 1px !important;

                .guide-accordion-image {
                    display: none !important;
                }

                .accordion-item-tile-inner-title {
                    width: 100% !important;
                }

                .arrow {
                    width: 60px !important;

                    .float-button-content {
                        width: 60px !important;
                        text-align: center;
                        margin: auto;
                        padding: 0;
                    }
                }
            }
        }

        .accordion-item-content {
            border-left-width: 1px !important;
        }
    }
}
