@import '../../styles/variables';

.error-page-header {
    width: 100%;
    padding-top: $grid-gutter-width;

    .content-header {
        .title-wrapper {
            display: flex;

            .img-container {
                img {
                    width: 56px;
                    height: 56px;
                }
            }
        }
    }
}

// page header background theme
.page-header-red-theme {
    background: linear-gradient(0deg, $bga-vanilla-ice, $bga-red-fail-mid) 0 0 no-repeat;

    .content-header {
        background-color: $bga-red-fail-light;
    }
}

@media (max-width: $screen-xs-max) {
    .error-page-header {
        padding-top: 0;

        > .container {
            padding: 0;
        }

        .content-header {
            .title-wrapper {
                flex-wrap: wrap;

                .img-container {
                    img {
                        width: 44px;
                        height: 44px;
                    }
                }
            }
        }
    }
}
