@import '../../styles/variables';
@import '../../styles/reactSelect';

.market-search__indicators {
    @include _react-select-indicators;
}

.market-search__control {
    @include _react-select-control;
}

.market-search__single-value {
    @include _react-select-single-value;
}

.market-search__menu {
    @include _react-select-menu;
}
